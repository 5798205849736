import React from 'react';
import { NavLink } from 'react-router-dom';
import MyAssets from '../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as config from '../const/config';
const LOCATION_URL = "https://www.google.com.sg/maps/place/D'Resort+@+Downtown+East/@1.3798904,103.9527757,17z/data=!3m1!4b1!4m8!3m7!1s0x31da3db5d8aee71d:0x4c13c8a73adf1cfc!5m2!4m1!1i2!8m2!3d1.3798904!4d103.9549644"
const styles = {
    subTitle:{
        textAlign:'left',
        margin:'15px 0px 0px 20px',
        fontWeight:'bold'
    },
    privacyContentFirstTitle:{
        fontWeight:'bold',
    },
    privacyContentRestTitle:{
        fontWeight:'normal',
    }
}
const generalInformationContent = () => (
    <ol style={{textAlign:'left',padding:'10px 20px 0px 20px'}}>
        <li>{/*1*/}
            In compliance with the Personal Data Protection Commission Singapore (PDPC)’s advisory guidelines on NRIC numbers issued on 31 August 2018, with effect from 1 September 2019, D’Resort will no longer accept check-ins on behalf of someone else. Registered guests are required to check-in personally for their stay.
        </li>
        <li>{/*2*/}
            Guests may not move furnishings or interfere with the electrical network or any other installations in the rooms or on the premises of D’Resort without the consent of the Management. Any damage to the room and the resort's fixtures, fittings and furnishings will be the responsibility of the guests and will be charged accordingly. A minimum $100 cleaning fee will be imposed for excessively messy or dirty rooms.
        </li>
        <li>{/*3*/}
            Smoking is only permitted at designated areas in D'Resort. All other premises in D'Resort have a non-smoking policy and $200 will be charged to your room for guests in violation of this.
        </li>
        <li>{/*4*/}
            In the event of false fire alarm activated by guests, a charge of $500 will be imposed.
        </li>
        <li>{/*5*/}
            Catering of food is only allowed through our appointed caterer. No other caterers are allowed in the resort premises. Catering venue will be advised accordingly.
        </li>
        <li>{/*6*/}
          For safety reasons, the charging of Portable Mobility Devices (PMDs) within D'Resort is not allowed.
        </li>
        <li>{/*7*/}
            No decorations or fixtures from external vendors are allowed in the room. Please email <a href="mailto:decorations@ntucclub.com.sg">decorations@ntucclub.com.sg</a> for more information on the appointed vendors. We reserved the rights to ask guest to remove any unauthorised decorations or fixtures. Should there be any decorations that damage the fittings, charges will apply.
        </li>
        <li>{/*8*/}
            Maximum guests permitted to stay in the room are based on room type and bedding configuration. For your safety, please abide strictly with the maximum room occupancy due to the Fire & Safety Code as stipulated by the SCDF Fire Code Review Committee and the FSSD Standing Committee. All visitors are to leave the resort premises after 11pm and the management reserves all rights to escort out any additional persons not complying with the Terms & Conditions.
        </li>
        <li>{/*9*/}
            Please be informed that the Goods and Services Tax (GST) will increase from 8% to 9% from 1 January 2024 onwards. The GST rate at 9% will be imposed on payments and transactions made on or after 1 January 2024.
        </li>
    </ol>
)
const privacyContent = () => (
    <ol style={{padding:'20px 20px 0px 20px',textAlign:'justify'}}>
        <li style={styles.privacyContentFirstTitle}>
            Personal Data Collected
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We may collect your Personal Data in various situations such as account / member sign-up, newsletter subscription, resort booking, event photography and participation in lucky draw / survey / event / activity, etc.
                </li>
                <li>
                    Personal Data collected by us may include name, email address, contact number, residential address, date of birth, bank account / credit card information, photographs and images, and information of the products and services you have purchased from us.
                </li>
                <li>
                    Where Personal Data of any third party are provided by you to us, you undertake to obtain the consent of the third party to our collection, use and/or disclosure of those Personal Data in accordance with our Privacy policy. We reserve the right to request for the verification of such consent where required.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Use of Your Personal Data
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    By interacting with us and submitting information to us, or signing up for any products or services offered by us, you agree and consent to us for collecting, using, and disclosing such Personal Data in the manner set forth in our Privacy policy.
                </li>
                <li>
                    We may collect and use your Personal Data for the following purposes:
                    <ol>
                        <li>To provide the products and / or services you have purchased.</li>
                        <li>To assist you with your enquiries.</li>
                        <li>To process payments for your purchases.</li>
                        <li>To improve customer service.</li>
                        <li>To conduct statistical analysis and research including building analytical solutions.</li>
                        <li>To keep you updated of our future offers / promotions and events.</li>
                        <li>To comply with applicable laws and regulations.</li>
                    </ol>
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Updating You of Our Offers / Promotions
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    You may be contacted via email, text message or voice call, to be informed of our latest offers or promotions.
                </li>
                <li>
                    You may also receive non-marketing messages such as surveys, customer-service notices and other service related notices if you have signed up as a member under any of our membership schemes or utilised any of our services / facilities.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Disclosure of Your Personal Data
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We may disclose your Personal Data to the following organisations for the purposes mentioned above:
                    <ol>
                        <li>NTUC and entities in the NTUC Group.</li>
                        <li>NTUC Club and its subsidiaries.</li>
                        <li>Financial institutions including credit card companies and their service providers.</li>
                        <li>Business partners.</li>
                        <li>Agents, contractors, data intermediaries or third party service providers.</li>
                        <li>Hospitals, clinics or insurance companies in connection with claims.</li>
                        <li>Professional advisor such as legal advisers, auditors.</li>
                        <li>Regulators or other government bodies as required by law.</li>
                    </ol>
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Use of Cookies
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We may use cookies on our websites to record session information, collect visit and access details to our web pages that enable us to continue to improve our services and provide better and useful features to our website users.
                </li>
                <li>
                    Should you wish to disable the cookies associated with these technologies you may do so by changing the settings on your browser. However, please note that this may affect the functionality of the website(s).
                </li>
                <li>
                    We are not responsible for the Personal Data policies (including Personal Data protection and cookies), content or security of any third party websites linked to our websites.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Protection of Your Personal Data
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We maintain appropriate security safeguards and practices to protect your Personal Data from accidental loss, misuse or destruction, in accordance with the applicable laws.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Accuracy of Your Personal Data
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We take all reasonable measures to ensure that your Personal Data remains accurate, complete and up-to-date. Please keep us informed when there are any changes to your Personal Data.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Access and Correction of your Personal Data
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    You may request access to or make corrections to your Personal Data records, subject to some exceptions allowed by law. A fee may be charged to cover the cost of providing the requested information and we will respond to your request within 30 days.
                </li>
                <li>
                    Please submit your request to us at this email address: dpo@ntucclub.com.sg
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Storage and Retention of Personal Data
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We will delete, as reasonably possible, or otherwise anonymise any Personal Data in the event that the Personal Data is not required for any reasonable business or legal purposes, according to our data retention policies.
                </li>
                <li>
                    We may from time to time transfer your Personal Data overseas to servers owned by third party service providers, for storage purposes and to continue to provide our services to you.
                </li>
                <li>
                    Where your Personal Data is transferred in this manner, we will ensure that the recipient of the Personal Data is bound by legally enforceable obligations to provide to the Personal Data a standard of protection that is at least comparable to that under the Personal Data Protection Act 2012.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Minors
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We cannot distinguish the age of persons accessing our websites. If a minor (according to applicable laws) has provided us with Personal Data without parental or guardian consent, the parent or guardian should contact us to inform us and we shall restrict any collection, use or disclosure of that personal data.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Contacting Us
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    If you have any questions about our Personal Data protection policy or practices, or wish to withdraw your consent to any use or disclosure of any Personal Data provided to us, you can write to dpo@ntucclub.com.sg
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Policy Updates
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    We may update our Privacy Policy at any time without prior notice in response to changes to applicable laws or our practices. You are advised to check our Privacy Policy on this page regularly.
                </li>
            </ol>
        </li>
        <li style={styles.privacyContentFirstTitle}>
            Governing Law
            <ol style={styles.privacyContentRestTitle}>
                <li>
                    This Policy is governed by and shall be construed in accordance with the laws of Singapore.
                </li>
            </ol>
        </li>
    </ol>
)
export default () => {
    return(
        <div id='footerPart'>
            <footer className="wrap footer">
                <div id="footer_divFooterTop">
                <div className="container">
                    <div className="row">

                    <div className="col-12 col-md-4 footer-main">
                        <h6><NavLink data-toggle="modal" data-target="#general-information" to="#">General Information</NavLink></h6>
                        <h6><a href="https://www.ntucclub.com/privacy-policy/" target="_blank">Privacy Policy</a></h6>
                        { config.IS_DEVELOPMENT ? <h6><NavLink to="/managebooking">Manage Booking</NavLink></h6> : null }
                        <br/>
                        <h6 style={{color:'#fff',fontSize:'14px'}}>Copyright © 2021 NTUC Club. All rights reserved.</h6>
                    </div>

                    <div className="col-md-4">
                    </div>

                    <div className="col-md-4">
                        <div className="footer-second-col d-none d-md-block d-xl-block">
                        <h6><strong>D`Resort @ Downtown East</strong></h6>
                        <br/>
                        {/*<h6><em><FontAwesomeIcon icon="phone"/></em><a href="callto:65823322">6582 3322</a></h6>*/}
                        <h6><em><FontAwesomeIcon icon="envelope"/></em><a href="mailto:reservations@dresort.com.sg">reservations@dresort.com.sg</a></h6>
                        <br/>
                        <h6><em><FontAwesomeIcon icon="map-marker"/></em><a href={LOCATION_URL} target="_blank">1 Pasir Ris Close, S519599</a></h6>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </footer>
            <div className="modal fade popUpModel" id="general-information" tabIndex="-1" role="dialog" aria-hidden="true">
                <div style={{maxWidth:1000}} className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-header">
                                <h4 className="modal-title">Need To Know</h4>
                                <button type="button" className="close" data-dismiss="modal">&#10005;</button>
                            </div>
                            <div style={styles.subTitle}>
                                Check-In
                            </div>
                            <ul>
                                <li>Check-in after 3pm.</li>
                                <li>Minimum check-in age: 18 years old.</li>
                                <li>Guests are required to present physical government-issued photo identification and credit card used for booking upon check-in for verification. Digital identification cards (Singpass) are not accepted.</li>
                                <li>Members are required to produce their physical NTUC Union/Link members card upon check-in for verification purposes and Linkpoints issuance. We are unable to issue linkpoints without the physical card.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Check-Out
                            </div>
                            <ul>
                                <li>Check-out before 11am.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Extra Bed & Baby Cot
                            </div>
                            <ul>
                                <li>Children 0 - 12 years occupying the same room with at least one adult and using existing bedding will not incur additional charges. Additional charges may apply if extra bed or linen are required.</li>
                                <li>Baby Cot is free.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Pets
                            </div>
                            <ul>
                                <li>Pets are not allowed.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Ground Floor / Connecting Room
                            </div>
                            <ul>
                                <li>Requests for ground floor and/or connecting rooms are subject to availability and surcharge of $30 (excl. GST) per room per night will apply. Confirmation of ground floor rooms will only be available upon arrival. Room numbers will be assigned upon check-in.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Parking
                            </div>
                            <ul>
                                <li>Parking charges apply. Parking lot availability is on a first come first served basis.</li>
                                <li>Parking lots are exclusively for D’Resort guests, multiple entry concession pass can be purchased at iCounter located at level 2 lobby (guests are required to provide vehicle IU number). Please visit <a target="_blank" href="https://www.dresort.com.sg/destination/getting-to-d'resort">https://www.dresort.com.sg/destination/getting-to-d'resort</a> or check with our friendly staff onsite.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Wild Wild Wet Admission
                            </div>
                            <ul>
                                <li>NTUC and nEbO members who book a room through D'Resort website are entitled unlimited access to Wild Wild Wet during their stay. Members must bring along their member card (Silver) upon check in; and Wild Wild Wet Day Passes will be issued based on the room occupancy and valid for the duration of stay.</li>
                                <li>Wild Wild Wet closed on Tuesday. Tickets for Wild Wild Wet are non-transferable to another day. Refer to <a href="https://www.wildwildwet.com/visitor-information/guest-services" target="_blank">https://www.wildwildwet.com/visitor-information/guest-services</a> for latest operating hours.</li>
                            </ul>

                            <div style={styles.subTitle}>
                                Catering Of Food
                            </div>
                            <ul>
                                <li>Catering of food is only allowed through our appointed caterer. Please visit <a target="_blank" href="https://www.dresort.com.sg/explore/eat">https://www.dresort.com.sg/explore/eat</a> for more details. No other caterers are allowed in the resort premises.</li>
                            </ul>

                            <div className="modal-header">
                                <h4 className="modal-title">Important Information</h4>
                            </div>
                            {/*<div style={styles.subTitle}>
                                Important Information
                            </div>*/}
                            {generalInformationContent()}
                            {/*<div style={styles.subTitle}>
                                Privacy Policy
                            </div>
                            <div style={{textAlign:'left',margin:'15px 0px 0px 20px'}}>
                                By accepting the term and condition, I have also read and accepted to the NTUC Club’s Privacy Policies http://www.ntucclub.com/privacy-policy/ which is incorporated into this Agreement by reference.
                            </div>
                            <br/>*/}
                            <div className="modal-header">
                                <h4 className="modal-title">Cancellation Policy</h4>
                            </div>
                            <div className="cancellation-policy">
                                <div id="cancellation-policy">
                                    <div style={styles.subTitle}>
                                        Booking under Best Flexible Rate
                                    </div>
                                    <ul>
                                        <li>Cancellation must be made 7 days prior to arrival to avoid 100% room charges including taxes.</li>
                                        <li>An administration fee of $15 (including GST) per room is applicable for any cancellation.</li>
                                    </ul>
                                </div>
                                <div id="cancellation-policy-rate" className="hidden">
                                    <div id="cancellation-policy-rate-title" style={styles.subTitle}>
                                        Booking under Best Flexible Rate
                                    </div>
                                    <ul id="cancellation-policy-rate-details">
                                        <li>Cancellation must be made 7 days prior to arrival to avoid 100% room charges including taxes.</li>
                                        <li>An administration fee of $15 (including GST) per room is applicable for any cancellation.</li>
                                    </ul>
                                    <p id="cancellation-policy-rate-description">
                                    </p>
                                </div>
                                <div style={styles.subTitle}>
                                    Promotional Bookings
                                </div>
                                <ul>
                                    <li>In the event of cancellation or modification, 100% of room charges are strictly non-refundable.</li>
                                </ul>
                                <div style={styles.subTitle}>
                                    BBQ Pit Cancellation Policy
                                </div>
                                <ul>
                                    <li>In the event of cancellation or inclement weather, 100% of BBQ pit booking charges are strictly non-refundable.</li>
                                </ul>
                                <div style={styles.subTitle}>
                                    No-Show / Early Departure
                                </div>
                                <ul>
                                    <li>In the event of a no-show, early departure or if guests fail to check-in on their stipulated date of arrival, 100% of room charges are strictly non-refundable.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade popUpModel" id="privacy-policy" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-header">
                                <h4 className="modal-title">Privacy Policy</h4>
                                <button type="button" className="close" data-dismiss="modal">&#10005;</button>
                            </div>
                            {privacyContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
