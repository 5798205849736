import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TermsConditions from './TermsConditions';

import "react-datepicker/dist/react-datepicker.css";

import * as allAPI from '../apis/all';
import * as config from '../const/config';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class PreRegister extends React.Component{
    static defaultProps = {
        bookingDetail: null,
        updateToken: null,
        countries: null,
        nationalities: null
    }

    constructor(props){
        super(props);

        let totalGuests = this.props.bookingDetail.total_adults + this.props.bookingDetail.total_children;
        let guestsDetails = [];

        // get guests details from localStorage

        for ( let index = 0; index < totalGuests; index++ ) {
            let guestDetail = {
                index: index + 1,
                name: index == 0 ? this.props.bookingDetail.name : '',
                guestEmail: index == 0 ? this.props.bookingDetail.email : '',
                guestIDD: index == 0 ? this.props.bookingDetail.idd_code : '',
                guestMobile: index == 0 ? this.props.bookingDetail.mobile : '',
                country: '',
                postalCode: '',
                address: '',
                nationality: '',
                gender: '',
                dob: '',
                dobYear: '',
                dobMonth: '',
                dobDate: '',
                passport: '',
                membershipType: '',
                membershipNo: '',
                isGuestNameValid: true,
                isEmailValid: true
            };

            guestsDetails.push(guestDetail);
        }

        this.state={
            bookingDetail: this.props.bookingDetail,
            updateToken: this.props.updateToken,
            guestsDetails: guestsDetails,
            totalGuests: totalGuests,
            countries: this.props.countries,
            nationalities: this.props.nationalities,
            eta: this.props.bookingDetail.time_of_arrival,
            purpose: this.props.bookingDetail.purpose_of_stay,
            canProceed: false,
            isAgreeTermPrivacyPolicy: false,
            totalYears: 100,
            totalDay: 31
        }

        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handleData = this.handleData.bind(this);
        this.handleDate = this.handleDate.bind(this);
    }

    checkEmailValidation = (email) => {
        if ( email == "" ) return true;

  		  const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  		  return emailReg.test(email);
  	}
  	checkNameValidation = (name) => {
        if ( name == "" ) return true;

  		  const nameReg = /^([a-zA-Z\s\/',\-\.]*)$/;
  		  return nameReg.test(name);
  	}
    handleCheckBox(event) {
				this.setState({ [event.target.name]: event.target.checked });

        this.checkData();
		}
    checkData = () => {
        let canProceed = true;
        for ( let index = 0; index < this.state.guestsDetails.length && canProceed; index++ ) {
            let guestDetail = this.state.guestsDetails[index];
            if ( guestDetail.name == "" || !guestDetail.isGuestNameValid ) {
                canProceed = false;
            }
            else if ( guestDetail.guestEmail == "" || !guestDetail.isEmailValid ) {
                canProceed = false;
            }
            else if ( guestDetail.guestIDD == "" || guestDetail.guestMobile == "" || guestDetail.nationality == "" || guestDetail.passport == "" ||
                guestDetail.gender == "" || guestDetail.dob == "" ) {
                canProceed = false;
            }
        }

        this.setState({ canProceed });
    }

    handleDate = (index, data, target) => {
        index -= 1;
        let guestsDetails = this.state.guestsDetails;
        let year = this.state.guestsDetails[index].dobYear;
        let month = this.state.guestsDetails[index].dobMonth;
        let date = this.state.guestsDetails[index].dobDate;
        let totalDay = this.state.totalDay;

        if ( target === 'dobYear' ) {
            guestsDetails[index].dobYear = data.target.value;
            year = data.target.value;
        }
        else if ( target === 'dobMonth' ) {
            guestsDetails[index].dobMonth = data.target.value;
            month = data.target.value;
        }
        else if ( target === 'dobDate' ) {
            guestsDetails[index].dobDate = data.target.value;
            date = data.target.value;
        }

        if ( year % 4 != 0 && month == 2 && date == 29 ) {
            guestsDetails[index].dobDate = 28;
        }
        else if ( date >= 31 && ( month == 1 || month == 3 || month == 5 || month == 8 || month == 10 || month == 12 ) ) {
            guestsDetails[index].dobDate = 31;
        }
        else if ( date >= 30 && ( month == 4 || month == 6 || month == 9 || month == 11 ) ) {
            guestsDetails[index].dobDate = 30;
        }

        if ( year % 4 == 0 && month == 2 ) {
            totalDay = 29;
        }
        else if ( year % 4 != 0 && month == 2 ) {
            totalDay = 28;
        }
        else if ( year != 4 && year != 6 && year != 9 && year != 11 ) {
            totalDay = 31;
        }
        else {
            totalDay = 30;
        }

        console.log(totalDay);

        this.setState({ guestsDetails, totalDay });
    };

    handleData = (index, data, target) => {
        let guestsDetails = undefined;
        index -= 1;
        if ( target === 'dob' ) {
            guestsDetails = this.state.guestsDetails;
            guestsDetails[index].dob = data;
        }
        else if ( target === 'eta' || target === 'purpose' ) {
            this.setState({ [target]: data.target.value });
        }
        else {
            guestsDetails = this.state.guestsDetails;
            switch( target ) {
                case 'name':
                    guestsDetails[index].name = data.target.value;
                    guestsDetails[index].isGuestNameValid = this.checkNameValidation(data.target.value);
                    break;
                case 'guestEmail':
                    guestsDetails[index].guestEmail = data.target.value;
                    guestsDetails[index].isEmailValid = this.checkEmailValidation(data.target.value);
                    break;
                case 'guestIDD':
                    guestsDetails[index].guestIDD = data.target.value;
                    break;
                case 'guestMobile':
                    guestsDetails[index].guestMobile = data.target.value;
                    break;
                case 'country':
                    guestsDetails[index].country = data.target.value;
                    break;
                case 'postalCode':
                    if( data.target.validity.valid ) {
                        guestsDetails[index].postalCode = data.target.value;
                    }
                    break;
                case 'address':
                    guestsDetails[index].address = data.target.value;
                    break;
                case 'nationality':
                    guestsDetails[index].nationality = data.target.value;
                    break;
                case 'gender':
                    guestsDetails[index].gender = data.target.value;
                    break;
                case 'passport':
                    guestsDetails[index].passport = data.target.value;
                    break;
                case 'membershipType':
                    guestsDetails[index].membershipType = data.target.value;
                    break;
                case 'membershipNo':
                    guestsDetails[index].membershipNo = data.target.value;
                    break;
            }
        }

        if ( guestsDetails !== undefined ) {
            this.setState({ guestsDetails });
        }

        this.checkData();

        console.log(this.state.guestsDetails);
        //console.log("ETA: " + this.state.eta);
        //console.log("Purpose: " + this.state.purpose);
    }

    loopCountries() {
        let dataToReturn = [];

        for ( let code in this.state.countries ) {
            dataToReturn.push(
                <option key={ code } value={ code }>
                    { this.state.countries[code].name }
                </option>
            );
        }

        return dataToReturn;
    }

    loopNationalities() {
        let dataToReturn = [];

        for ( let code in this.state.nationalities ) {
            dataToReturn.push(
                <option key={ code } value={ code }>
                    { this.state.nationalities[code].name }
                </option>
            );
        }

        return dataToReturn;
    }

    loopYears() {
        let dataToReturn = [];
        let currentYear = new Date();
        currentYear = currentYear.getFullYear();

        for ( let year = currentYear; year > ( currentYear - this.state.totalYears ); year-- ) {
            dataToReturn.push(
                <option key={ year } value={ year }>
                    { year }
                </option>
            );
        }

        return dataToReturn;
    }
    loopMonths() {
        let dataToReturn = [];
        let monthsText = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        for ( let monthIndex in monthsText ) {
            dataToReturn.push(
                <option key={ monthsText[monthIndex] } value={ monthIndex + 1 }>
                    { monthsText[monthIndex] }
                </option>
            );
        }

        return dataToReturn;
    }
    loopDays() {
        let dataToReturn = [];

        for ( let day = 1; day < (this.state.totalDay + 1); day++ ) {
            dataToReturn.push(
                <option key={ day } value={ day }>
                    { day }
                </option>
            );
        }

        return dataToReturn;
    }

    loopGuests() {
        if ( this.state.guestsDetails && this.state.guestsDetails.length > 0 ) {
            let guestDetails = this.state.guestsDetails.map(i => {
    						return <fieldset key={i.index} style={{ marginBottom: '20px' }}>
                          <legend>Guest { i.index }</legend>

                          <p>Please fill in the details below for pre-registration.</p>

                          <div className="row">
                              <div className="col-12 col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="usr">Name <span className="asterix">*</span></label>
                                    <input name="name" type="text" className="form-control" value={ i.name }
                                        disabled={ (i.index - 1) == 0 ? true : false }
                                        readOnly={ (i.index - 1) == 0 ? true : false }
                                        onChange={ (e) => this.handleData(i.index, e, 'name') }
                                        placeholder="Full name as shown on Passport or NRIC" />
                                    {
                                      i.isGuestNameValid ? null :
                                      <p style={{
                                        marginTop: '7px',
                                        color: 'red',
                                        fontWeight: 'bold',
                                      }}>Name is Invalid. Name can only contains alphabet, space, and special characters such as /, ', -, ., and (,).</p>
                                    }
                                  </div>

                                  <div className="form-group">
            												<label htmlFor="usr">Email <span className="asterix">*</span></label>
            												<input onBlur={this.handleEmailBlur} name="guestEmail" type="email" className="form-control" value={ i.guestEmail }
                                        disabled={ (i.index - 1) == 0 ? true : false }
                                        readOnly={ (i.index - 1) == 0 ? true : false }
                                        onChange={ (e) => this.handleData(i.index, e, 'guestEmail') }
                                        placeholder="Provide accurate email to receive booking confirmation" />
            												{
            													i.isEmailValid ? null :
            													<p style={{
            														marginTop: '7px',
            														color: 'red',
            														fontWeight: 'bold',
            													}}>Email is Invalid</p>
            												}
            											</div>

            											<div className="form-group mb-0">
            												<label htmlFor="usr">Mobile <span className="asterix">*</span></label>
            											</div>
            											<div className="form-group input-group form-mobile">
            												<div className="input-group-prepend">
            													<input pattern="/^\+(?:[\d]*)/g" type="text" name="guestIDD" className="form-control"
                                          value={ i.guestIDD }
                                          disabled={ (i.index - 1) == 0 ? true : false }
                                          readOnly={ (i.index - 1) == 0 ? true : false }
                                          placeholder="+IDD Code"
                                          maxLength="4"
                                          onChange={ (e) => this.handleData(i.index, e, 'guestIDD') } />
            												</div>
            												<input pattern="[0-9]*" type="text" name="guestMobile" className="form-control"
                                        value={ i.guestMobile }
                                        disabled={ (i.index - 1) == 0 ? true : false }
                                        readOnly={ (i.index - 1) == 0 ? true : false }
                                        maxLength="12"
                                        onChange={ (e) => this.handleData(i.index, e, 'guestMobile') } />
            											</div>

                                  <div className="form-group">
                                    <label htmlFor="usr">Nationality <span className="asterix">*</span></label>
                                    <select className="form-control" defaultValue={ i.nationality }  onChange={ (e) => this.handleData(i.index, e, 'nationality') }>
                                        <option value="">Select Nationality</option>
                                        { this.loopNationalities() }
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">Passport/Singapore IC Number <span className="asterix">*</span></label>
                                    <div className="row">
                                        <div className="col-12 col-lg-8">
                                            <input name={ "passport_" + i.index } type="text" className="form-control" defaultValue={ i.passport } onBlur={ (e) => this.handleData(i.index, e, 'passport') } placeholder="Your Passport / Singapore IC Number" />
                                        </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">Gender <span className="asterix">*</span></label>
                                    <select name={ "gender_" + i.index } className="form-control" onChange={ (e) => this.handleData(i.index, e, 'gender') }>
                                        <option value="">Select Gender</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                        <option value="UNKNOWN">Unknown</option>
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">Date of Birth <span className="asterix">*</span></label>
                                    <div className="row">
                                        <div className="col-12 col-lg-4">
                                        {/*<DatePicker
                                            placeholderText="Your Date of Birth"
                                            className="form-control"
                                            dateFormat="dd MMM yyyy"
                                            selected={ i.dob }
                                            onChange={ (date) => this.handleData(i.index, date, 'dob') } //only when value has changed
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            maxDate={new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate())}
                                        />*/}
                                            <select className="form-control"  value={ i.dobYear } onChange={ (e) => this.handleDate(i.index, e, 'dobYear') }>
                                                <option value="">Select Year</option>
                                                { this.loopYears() }
                                            </select>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <select className="form-control"  value={ i.dobMonth } onChange={ (e) => this.handleDate(i.index, e, 'dobMonth') }>
                                                <option value="">Select Month</option>
                                                { this.loopMonths() }
                                            </select>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <select className="form-control"  value={ i.dobMonth } onChange={ (e) => this.handleDate(i.index, e, 'dobDate') }>
                                                <option value="">Select Date</option>
                                                { this.loopDays() }
                                            </select>
                                        </div>
                                    </div>
                                  </div>
                              </div>

                              <div className="col-12 col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="usr">Address</label>
                                    <textarea style={{ height: '124px' }} name={ "address_" + i.index } className="form-control" rows="4"
                                        defaultValue={ i.address }
                                        onChange={ (e) => this.handleData(i.index, e, 'address') }></textarea>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">Country</label>
                                    <select className="form-control"  defaultValue={ i.country } onChange={ (e) => this.handleData(i.index, e, 'country') }>
                                        <option value="">Select Country</option>
                                        { this.loopCountries() }
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">Postal Code</label>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <input name="postal_code" type="text" className="form-control"
                                                value={ i.postalCode }
                                                onChange={ (e) => this.handleData(i.index, e, 'postalCode') }
                                                pattern="[0-9]*"
                                                placeholder="Your Address's Postal Code" />
                                        </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">NTUC Membership Type</label>
                                    <div className="row">
                                        <div className="col-12 col-lg-8">
                                            <select className="form-control" onChange={ (e) => this.handleData(i.index, e, 'membershipType') }>
                                                <option value="">Select Membership Type</option>
                                                <option value="NTUC Plus! Card (Silver card)">NTUC Plus! Card (Silver card)</option>
                                                <option value="Nebo">Nebo</option>
                                                <option value="NTUC Plus! Card (Silver card)">NTUC Plus! Card (Black card)</option>
                                            </select>
                                        </div>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="usr">NTUC Membership Number</label>
                                    <div className="row">
                                        <div className="col-12 col-lg-8">
                                            <input name={ "membership_number_" + i.index } type="text" className="form-control"
                                                defaultValue={ i.membershipNo }
                                                onBlur={ (e) => this.handleData(i.index, e, 'membershipNo') }
                                                placeholder="Your NTUC Membership Number" />
                                        </div>
                                    </div>
                                  </div>

                                  {/*<div className="form-group">
                                    <label htmlFor="usr">Upload Passport / Singapore IC <span className="asterix">*</span></label>
                                    <input name={ "upload_" + i.index } style={{ border: 'none', paddingLeft: '0' }} type="file" className="form-control" value={ i.upload } onBlur={ this.handleNameBlur } onChange={ this.handleData } placeholder="Your Address's Postal Code" />
                                  </div>*/}
                              </div>


                          </div>

                      </fieldset>
    				});

            return guestDetails;
        }

        return null;
    }

    loopArrivalTime = () => {
				let dataToReturn = [];
				let startTime = 15;
				let endTime = 6;
				let nextDayLimit = 24;
				let isStop = false;
				let isNextDay = false;
				let time = '';

				let count = 30;

				while ( !isStop || count === 0 ) {
						if ( startTime === (nextDayLimit - 1) ) {
								time = startTime.toString().padStart(2, '0') + ":00 - 00:00";
						}
						else {
								time = startTime.toString().padStart(2, '0') + ":00 - " + (startTime + 1).toString().padStart(2, '0') + ":00" + ( isNextDay ? " (The Next Day)" : "" );
						}

						dataToReturn.push(
								<option key={ time } value={ time }>{ time }</option>
						);

						startTime++;

						if ( startTime === nextDayLimit ) {
								startTime = 0;
								isNextDay = true;
						}

						if ( startTime === endTime ) {
								isStop = true;
						}

						count--;
				}

				return dataToReturn;
		}

    preregister() {
        this.props.preregister(this.state.bookingDetail.id, this.state.guestsDetails, this.state.eta, this.state.purpose, this.state.updateToken);
    }

    render(){
        let totalNights = 0;
        let checkInDate = '';
        let checkOutDate = '';

        if ( this.state.bookingDetail ) {
            checkInDate = moment(this.state.bookingDetail.check_in_date);
            checkOutDate = moment(this.state.bookingDetail.check_out_date);

            totalNights = checkOutDate.diff(checkInDate, 'day');
        }

        return (
            <div className="pre-register">
              <div className="booking-detail">
                  <div style={{fontSize:'22px'}}>Your Reservation ID: { this.state.bookingDetail.id }</div>

                  <div style={{margin:'30px auto', fontSize:'14px', fontWeight:'bold'}}>
                    <table style={{width:'100%'}} border="0">
                      <tbody>
                        <tr>
                          <th style={{textAlign:'left'}}>Room Type:</th>
                          <th style={{textAlign:'right'}}>{ this.state.bookingDetail.room_type_name }</th>
                        </tr>
                        <tr>
                          <th style={{textAlign:'left'}}>Reservations:</th>
                          <th style={{textAlign:'right'}}>{ this.state.bookingDetail.total_rooms } Room{ this.state.bookingDetail.total_rooms > 1 ? 's' : null }, { totalNights } Night{ totalNights > 1 ? 's' : null }</th>
                        </tr>
                        <tr>
                          <th style={{textAlign:'left'}}>Occupancy Per Room:</th>
                          <th style={{textAlign:'right'}}>
                            { this.state.bookingDetail.total_adults } Adult{this.state.bookingDetail.total_adults > 1 ? 's' : null}
                            { this.state.bookingDetail.total_children > 0 ? ', ' + this.state.bookingDetail.total_children + ' children' : null }
                          </th>
                        </tr>
                        <tr>
                          <th style={{textAlign:'left'}}>Check in:</th>
                          <th style={{textAlign:'right'}}>{ checkInDate.format('DD MMM YYYY') }</th>
                        </tr>
                         <tr>
                          <th style={{textAlign:'left'}}>Check out:</th>
                          <th style={{textAlign:'right'}}>{ checkOutDate.format('DD MMM YYYY') }</th>
                        </tr>
                         {/*<tr>
                          <th style={{textAlign:'left'}}>Reservation ID:</th>
                          <th style={{textAlign:'right'}}>{ this.state.bookingID }</th>
                        </tr>*/}
                      </tbody>
                    </table>
                  </div>

                  { this.loopGuests() }

                  <div className="row">
                      <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label htmlFor="purposeOfStay">Purpose of stay</label>
                            <select id="purposeOfStay" defaultValue={ this.state.purpose } className="form-control" onChange={ (e) => this.handleData(0, e, 'purpose') }>
                                <option value="">Select purpose of stay</option>
                                <option value="Birthday Celebration">Birthday Celebration</option>
                                <option value="Gathering">Gathering</option>
                                <option value="Business Trip">Business Trip</option>
                                <option value="Transit">Transit</option>
                                <option value="Holiday">Holiday</option>
                                <option value="Staycation">Staycation</option>
                                <option value="Anniversary/Honeymoon">Anniversary/Honeymoon</option>
                            </select>
                          </div>
                      </div>

                      <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label htmlFor="eta">Estimated Time of Arrival</label>
                            <select id="eta" defaultValue={ this.state.eta } className="form-control" onChange={ (e) => this.handleData(0, e, 'eta') }>
                                <option value="I don't know">I don't know</option>
                                { this.loopArrivalTime() }
                            </select>
                            Check-in time after 3pm, check out before 11am.
                          </div>
                      </div>
                  </div>

                  <div className="row mt-2">
        						<div className="col-md-12">
        							<div className="form-check custom-checkbox">
        								<input name="isAgreeTermPrivacyPolicy" type="checkbox" id="isAgreeTermPrivacyPolicy" checked={this.state.isAgreeTermPrivacyPolicy} onChange={this.handleCheckBox } />
        								<span className="checkmark"></span>
        								<label className="form-check-label" htmlFor="isAgreeTermPrivacyPolicy">
                        		I confirm that I have obtained consent from the other guests named below for the collection, use and disclosure of their personal data to NTUC Club for security purposes. In addition, I also acknowledge that I have read and agree to the <a target="_blank"  data-toggle="modal" data-target="#booking-tc-information" href="/book" className="text-success">Terms &amp;	Conditions</a> set out by the Resort and NTUC Club’s <a target="_blank" href="https://www.ntucclub.com/privacy-policy/" className="text-success">Privacy Policy</a> which is incorporated into this Agreement by reference. Please note our Privacy Policy may be updated from time to time without notice.
        								</label>
        							</div>
        						</div>
        					</div>

                  <TermsConditions />

                  <div className="row">
                      <div className="col-12 text-center">
                          <div className="form-group mb-0">
                              <button className="btn-md btn btn-success btn-square mt-4 mt-md-2 col-12 col-md-3" id="checkout"
                                  disabled={ !this.state.canProceed || !this.state.isAgreeTermPrivacyPolicy }
                                  onClick={ this.preregister.bind(this) }>
                                  PRE-REGISTER
                              </button>
                              <button className="btn-md btn btn-danger btn-square mt-4 mt-md-2 col-12 col-md-3 ml-md-4" id="checkout" onClick={ () => { this.props.backToBookingInformation(); } }>
                                  CANCEL
                              </button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let params = { retrieveBookingStatus: false, data: null, cancelBookingStatus: false, cancelBookingData: null, modifyBookingStatus: false, modifyBookingData: null }

    console.log(state);

    /*if ( state.bookingDetail.result ) {
        params.retrieveBookingStatus = true;
        params.data = state.bookingDetail.result;
    }

		if ( state.cancelBooking.result ) {
				params.cancelBookingStatus = true;
				params.cancelBookingData = state.cancelBooking.result;
		}

		if ( state.modifyBooking.result ) {
				params.modifyBookingStatus = true;
				params.modifyBookingData = state.modifyBooking.result;
		}*/
    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { preregister } = allAPI;
  return bindActionCreators({ preregister }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(PreRegister));
