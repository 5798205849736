import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileView, ImgWithTooltip } from '../../misc/helper_functions';
import { _getLocalStorage, _formatDate, _formatAmount } from '../../functions/helper_functions';
import { _loading } from '../../functions/message_functions';
import $ from 'jquery';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DOMPurify from 'dompurify';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

import BackgroundImage from '../../assets/img/DResort-Booking.jpg';
import NoRoom from '../../assets/img/no-room-available.png';

import LightboxComponent from '../../component/LightboxComponent';
import MyAssets from '../../assets';

import SweetAlert from 'sweetalert2';

const COUNT_PERSON = {
    PLUS:"PLUS",
    MIN:"MIN",
    ADULT:"ADULT",
    CHILDREN:"CHILDREN"
}
const DATE_NOW_STRING = moment().format('DD MMM YYYY');

var contactFirstOffSet = null;
class Home extends React.Component{
  constructor(props){
      super(props);

      if ( this.getQueryParam("arr") && this.getQueryParam("dep") ) {
        let arr = moment(this.getQueryParam("arr"));
        let dep = moment(this.getQueryParam("dep"));

        if ( arr.isValid() ) {
          localStorage.setItem(config.KEY_CHECK_IN_DATE, arr.format('YYYY-MM-DD'));
        }
        else {
          arr = moment().format('YYYY-MM-DD');

          localStorage.setItem(config.KEY_CHECK_IN_DATE, arr.format('YYYY-MM-DD'));
        }

        if ( dep.isValid() ) {
          if ( dep.isAfter(arr) ) {
            localStorage.setItem(config.KEY_CHECK_OUT_DATE, dep.format('YYYY-MM-DD'));
          }
          else {
            localStorage.setItem(config.KEY_CHECK_OUT_DATE, arr.add(1, 'day').format('YYYY-MM-DD'));
          }
        }
        else {
          localStorage.setItem(config.KEY_CHECK_OUT_DATE, arr.add(1, 'day').format('YYYY-MM-DD'));
        }
      }
      else if ( this.getQueryParam("arr") ) {
        let arr = moment(this.getQueryParam("arr"));

        if ( arr.isValid() ) {
          localStorage.setItem(config.KEY_CHECK_IN_DATE, arr.format('YYYY-MM-DD'));
        }
        else {
          arr = moment().format('YYYY-MM-DD');

          localStorage.setItem(config.KEY_CHECK_IN_DATE, arr.format('YYYY-MM-DD'));
        }

        localStorage.setItem(config.KEY_CHECK_OUT_DATE, arr.add(1, 'day').format('YYYY-MM-DD'));
      }
      else if ( this.getQueryParam("dep") ) {
        let dep = moment(this.getQueryParam("dep"));

        if ( dep.isValid() ) {
          localStorage.setItem(config.KEY_CHECK_OUT_DATE, dep.format('YYYY-MM-DD'));
        }
        else {
          dep = moment().format('YYYY-MM-DD');

          localStorage.setItem(config.KEY_CHECK_OUT_DATE, dep.format('YYYY-MM-DD'));
        }

        localStorage.setItem(config.KEY_CHECK_IN_DATE, dep.add(-1, 'day').format('YYYY-MM-DD'));
      }

      if ( this.getQueryParam("room") ) {
          let room = this.getQueryParam("room");
          if ( room !== "" && room !== true && !isNaN(room) ) {
              localStorage.setItem(config.KEY_TOTAL_ROOMS, parseInt(this.getQueryParam("room")));
          }
      }

      if ( this.getQueryParam("adult") && this.getQueryParam("children") ) {
          let adult = this.getQueryParam("adult");
          let child = this.getQueryParam("children");
          if ( adult !== "" && adult !== true && !isNaN(adult) ) {
              adult = parseInt(adult);
          }

          if ( child !== "" && child !== true && !isNaN(child) ) {
              child = parseInt(child);
          }

          if ( (adult + child) <= 4 ) {
              localStorage.setItem(config.KEY_TOTAL_ADULT, adult);
              localStorage.setItem(config.KEY_TOTAL_CHILDREN, child);
          }
          else {
              this.urlTotalAdultChildrenErrorMessage();
          }
      }
      else if ( this.getQueryParam("adult") ) {
          let adult = this.getQueryParam("adult");
          if ( adult !== "" && adult !== true && !isNaN(adult) ) {
              let currentChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
              currentChildren = (currentChildren === undefined || currentChildren === null) ? 0 : parseInt(currentChildren);
              adult = parseInt(adult);

              if ( (adult + currentChildren) <= 4 ) {
                  localStorage.setItem(config.KEY_TOTAL_ADULT, adult);
              }
              else {
                  this.urlTotalAdultChildrenErrorMessage();
              }
          }
      }
      else if ( this.getQueryParam("children") ) {
          let child = this.getQueryParam("children");
          if ( child !== "" && child !== true && !isNaN(child) ) {
              let currentAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
              currentAdult = (currentAdult === undefined || currentAdult === null) ? 0 : parseInt(currentAdult);
              child = parseInt(child);

              if ( (child + currentAdult) <= 4 ) {
                  localStorage.setItem(config.KEY_TOTAL_CHILDREN, child);
              }
              else {
                  this.urlTotalAdultChildrenErrorMessage();
              }
          }
      }

      if ( this.getQueryParam("code_type") && this.getQueryParam("code") ) {
          let codeType = this.getQueryParam("code_type");
          let code = this.getQueryParam("code");
          let isCodeTypeError = false;

          if ( codeType !== "" && code !== "" ) {
              codeType = codeType.toLowerCase();
              code = code.toLowerCase();

              if ( codeType === "promo" || codeType === "corporate" || codeType === "group" ) {
                  codeType = (codeType === "promo") ? "Promo Code" : ( (codeType === "corporate") ? "Corporate code" : "Group Code" );

                  localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, codeType);
                  localStorage.setItem(config.KEY_SPECIAL_CODE, code);
              }
              else {
                  isCodeTypeError = true;

                  localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, "");
                  localStorage.setItem(config.KEY_SPECIAL_CODE, "");
              }
          }

          if ( isCodeTypeError ) {
            SweetAlert.fire({icon:'warning',
                title:'Invalid Special Code',
                html: '<p class="text-left">You are requesting for invalid special code</p><p class="text-left">If you have the special code, please do a search with the special code.</p>',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass:{confirmButton:'btn btn-success'}});
          }
      }

      let isNewGST = false;
      if ( this.getQueryParam("newGST") ) {
          isNewGST = this.getQueryParam("newGST");
          if ( isNewGST !== "" && isNewGST === "true" ) {
              isNewGST = true;
          }
      }

      // advance: 30
      // arrival date is 30 days from today
      // URL Generator

      let checkInDate = _getLocalStorage(config.KEY_CHECK_IN_DATE);
      let checkOutDate = _getLocalStorage(config.KEY_CHECK_OUT_DATE);
      let totalNight = _getLocalStorage(config.KEY_TOTAL_NIGHT);
      let totalRoom = _getLocalStorage(config.KEY_TOTAL_ROOMS);
      let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
      let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
      let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);
      let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);//console.log("specialCode="+specialCode);console.log("specialCodeType="+specialCodeType);
      let isMember = _getLocalStorage(config.KEY_IS_MEMBER);
      let updateToken = _getLocalStorage(config.KEY_UPDATE_TOKEN);
      let modifyBookingID = _getLocalStorage(config.KEY_UPDATE_BOOKING_ID);
      let isMemberPreviousBooking = _getLocalStorage(config.KEY_IS_MEMBER_PREVIOUS_BOOKING);
      // let isModifyBookingHasLoaded = _getLocalStorage(config.KEY_MODIFY_BOOKING_HAS_LOADED);

      //console.log(checkInDate + " " + checkOutDate);
      // console.log("THERE");
      // console.log(modifyBookingID + " " + updateToken);

      // check whether check in date is after today
      if ( moment(checkInDate) < moment() && moment(checkInDate).format('DD') != moment().format('DD') ) {
          checkInDate = moment().format('YYYY-MM-DD');
          checkOutDate = moment().add(1, 'day').format('YYYY-MM-DD');
          totalNight = 1;
      }

      let checkInStartDate = moment(DATE_NOW_STRING);
      let checkOutStartDate = moment(DATE_NOW_STRING).add(1, 'day');

      //console.log(checkInStartDate);
      //console.log(checkInDate.diff(checkInStartDate, 'days'));

      if ( checkInDate == null && checkOutDate == null ) {
          checkInDate = checkInStartDate.format('YYYY-MM-DD');
          checkOutDate = checkOutStartDate.format('YYYY-MM-DD');
      }
      else if ( moment(checkInDate) < checkInStartDate ) {
          checkInDate = checkInStartDate.format('YYYY-MM-DD');
          checkOutDate = checkOutStartDate.format('YYYY-MM-DD');
      }

      if ( isMember === null ) isMember = false;

      this.state={
        currentBookedTotal:1,
        totalAdult: totalAdult != null ? parseInt(totalAdult) : 1,
        totalChildren: totalChildren != null ? parseInt(totalChildren) : 0,
        specialCodeType: specialCodeType != null ? specialCodeType : '',
        specialCode: specialCode != null ? specialCode : '',
        isShowAll: false,
        rooms: null,
        isSearch: false,
        checkInDate: checkInDate ? moment(checkInDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        checkOutDate: checkOutDate ? moment(checkOutDate).format('YYYY-MM-DD') : moment().add(1, 'day').format('YYYY-MM-DD'),
        startDateString: checkInDate ? moment(checkInDate).format('DD MMM YYYY') : moment().format('DD MMM YYYY'),
        endDateString: checkOutDate ? moment(checkOutDate).format('DD MMM YYYY') : moment().add(1,'day').format('DD MMM YYYY'),
        startDateRangePickerValue: checkInDate ? moment(checkInDate) : moment(),
        endDateRangePickerValue: checkOutDate ? moment(checkOutDate) : moment().add(1,'day'),
        persons: totalAdult != null ? `${totalRoom} Room${totalRoom>1?'s':''}, ${totalAdult} Adult${totalAdult>1?'s':''}, ${totalChildren} Child${totalChildren<=1?'':'ren'}` : '1 Room, 1 Adult, 0 Children',
        totalNight: totalNight ? totalNight : 1,
        totalRooms: totalRoom != null ? parseInt(totalRoom) : 1,
        totalAPIRooms: 0,
        roomName: '',
        roomCode: '',
        ratePlanCode: '',
        ratePlanName: '',
        ratePolicy: null,
        isProcessBooking: false,
        specialRequests: [],
        isMember: isMember,
        isFirstLoad: true,
        updateToken: updateToken,
        modifyBookingID: modifyBookingID,
        hasModifyParams: this.getQueryParam("mode") == "change",
        isMemberPreviousBooking: isMemberPreviousBooking,
        /* specialCode: '',
        specialCodeType: '', */
        specialCodeText: specialCodeType != null && specialCodeType != "" ? specialCodeType + ", " + specialCode : '',
        isNoAvailableRoom: false,
        endDateMinDate: ( checkInDate ? moment(checkInDate).add(1,'day') : moment().add(1,'day') ),
        specialCodeAlert:false,
        isInvalidPromoCode: false,
        isLongTermCode: specialCodeType == "Long Term Code" ? true : false,
        isNewGST: isNewGST,
        maintenanceMessage: null/*<div>
        <p><strong>SCHEDULED SITE MAINTENANCE</strong></p>
        <p>Kindly note that we will be experiencing downtime for scheduled maintenance and upgrades on <strong>from 12 Feb 2020, 11:00pm to 13 Feb 2020, 05:00am (6 hours) | SGT</strong></p>
        <p>We apologize for any inconvenience caused.</p>
        </div>*/
        /*from 05 Dec 2019, 11:00pm to 06 Dec 2019, 03:00am (4 hours) | SGT*/
        /*08 Dec 2019 | 03:00 to 06:00am | SGT*/
      }

      //this.clearModificationData = this.clearModificationData.bind(this);
  }
  getQueryParam(searchString) {
      var results = new RegExp('[\?&]' + searchString + '=([^&#]*)').exec(window.location.search);
      if (results == null) {
          return null;
      }
      else {
          return decodeURIComponent(results[1]) || 0;
      }
  }
  componentDidMount(){
      window.scrollTo(0, 0);
      this.props.getContent();
      this.searchRoom();
      this.setState({ isFirstLoad: false });
      $("#login").show();

      window.addEventListener('touchstart',this.handleClick);

      let updateToken = _getLocalStorage(config.KEY_UPDATE_TOKEN);
      let modifyBookingID = _getLocalStorage(config.KEY_UPDATE_BOOKING_ID);
      let isMemberPreviousBooking = _getLocalStorage(config.KEY_IS_MEMBER_PREVIOUS_BOOKING);
      // let isModifyBookingHasLoaded = _getLocalStorage(config.KEY_MODIFY_BOOKING_HAS_LOADED);

      localStorage.removeItem(config.KEY_TEMP_MODIFY_BOOKING_DATA);

      window.onbeforeunload = function() {
          this.clearModificationData.bind(this);
      }.bind(this);

      /*if ( updateToken && modifyBookingID ) {
          if ( !isModifyBookingHasLoaded ) {
              localStorage.setItem(config.KEY_MODIFY_BOOKING_HAS_LOADED, true);
          }
      }*/
  }

  clearModificationData = () => {
      console.log("BEFORE Unload");
      localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);
      localStorage.removeItem(config.KEY_UPDATE_TOKEN);
  }

  componentWillUnmount(){
      window.removeEventListener('touchstart',this.handleClick);
  }

  urlTotalAdultChildrenErrorMessage() {
      SweetAlert.fire({icon:'warning',
          title:'Total Adult and Children is more than 4',
          html: '<p class="text-left">You are requesting for more than 4 pax to stay in the room which is not allowed.<br />The total adult is set to 1.<br />The total children is set to 0.</p><p class="text-left">Please do a search.</p>',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass:{confirmButton:'btn btn-success'}});

      localStorage.setItem(config.KEY_TOTAL_ADULT, 1);
      localStorage.setItem(config.KEY_TOTAL_CHILDREN, 0);
  }

  removeReservationDetail = (target) => {
    $(`.data-book-${target}`).remove();
  }

  handleSpecialCodeType(event) {
      if ( event.target.value === 'Select Code Type' ) {
          this.setState({ specialCodeType: '', specialCodeText: '' });
      }
      else {
          this.setState({ specialCodeType: event.target.value, specialCodeText: event.target.value + ", " + this.state.specialCode });
      }
  }

  handleSpecialCode(event) {
    if(event.target.value===''){
      this.setState({ specialCode: event.target.value, specialCodeText: '' });
    }else{
      this.setState({ specialCode: event.target.value, specialCodeText: this.state.specialCodeType + ", " + event.target.value });
    }
  }

  addReservation = (roomCode, ratePlanCode, specialRequests, roomName, ratePlanName, ratePolicy) => {
      let checkInDate = _getLocalStorage(config.KEY_CHECK_IN_DATE);
      let checkOutDate = _getLocalStorage(config.KEY_CHECK_OUT_DATE);
      let totalNight = _getLocalStorage(config.KEY_TOTAL_NIGHT);
      let totalRooms = _getLocalStorage(config.KEY_TOTAL_ROOMS);
      let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
      let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
      let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);
      let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);

      let message = new Array();
      let hasChanges = false;
      if ( this.state.checkInDate.toString() !== checkInDate.toString() ) {
          //message.push("<li>Your previous arrival date is " + _formatDate(checkInDate) + ".</li>");
          hasChanges = true;
      }
      if ( this.state.checkOutDate.toString() !== checkOutDate.toString() ) {
          //message.push("<li>Your previous departure date is " + _formatDate(checkOutDate) + ".</li>");
          hasChanges = true;
      }
      if ( this.state.totalRooms.toString() !== totalRooms.toString() ) {
          //message.push("<li>Your previous total room is " + totalRooms + ".</li>");
          hasChanges = true;
      }
      if ( this.state.totalAdult.toString() !== totalAdult.toString() ) {
          //message.push("<li>Your previous total adult is " + totalAdult + ".</li>");
          hasChanges = true;
      }
      if ( this.state.totalChildren.toString() !== totalChildren.toString() ) {
          //message.push("<li>Your previous total children is " + totalChildren + ".</li>");
          hasChanges = true;
      }
      if ( this.state.specialCodeType.toString() !== specialCodeType.toString() ) {
          if ( specialCodeType === "" ) {
              //message.push("<li>Your previous special code type is not selected.</li>");
              hasChanges = true;
          }
          else {
              //message.push("<li>Your previous special code type is " + specialCodeType + ".</li>");
              hasChanges = true;
          }
      }
      if ( this.state.specialCode.toString() !== specialCode.toString() ) {
          if ( specialCodeType === "" ) {
              //message.push("<li>Your previous special code is not set.</li>");
              hasChanges = true;
          }
          else {
              //message.push("<li>Your previous special code is " + specialCode + ".</li>");
              hasChanges = true;
          }

      }

      let isProcess = true;
      if ( hasChanges ) {
          //message.splice(0, 0, "You have changed the search data without doing a search.");

          message.push("Please click on <strong>Search</strong> button to check on the rate and availability.");
          message.push("<br /><br />");
          message.push("Please click on <strong>Proceed</strong> button to continue booking the selected room based on previous search criteria.");

          message.push("<ul class='text-left'>");
          message.push("<li>Arrival Date: " + _formatDate(checkInDate) + "</li>");
          message.push("<li>Departure Date: " + _formatDate(checkOutDate) + "</li>");
          message.push("<li>Total Room(s): " + totalRooms + "</li>");
          message.push("<li>Total Adult(s): " + totalAdult + "</li>");
          message.push("<li>Total Children: " + totalChildren + "</li>");
          if ( specialCodeType !== "" ) {
              message.push("<li>Special Code Type: " + specialCodeType + "</li>");
          }
          if ( specialCode !== "" ) {
              message.push("<li>Special Code: " + specialCode + "</li>");
          }
          message.push("</ul>");

          message = message.join("");
          //isProcess = window.confirm(message);

          SweetAlert.fire({icon:'warning',
              title:'You have changed the search criteria without doing a search.',
              html: '<p class="text-left">' + message + '</p>',
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: 'Search',
              cancelButtonText: 'Proceed',
              customClass:{confirmButton:'btn btn-success', cancelButton: 'btn btn-success btn-clear ml-2'}})
          .then((result) => {
              if (result.value && result.value.toString() === "true") {
                  this.searchRoom();
              } else if ( result.dismiss && result.dismiss.toString() === "cancel" ) {
                  //this.props.roomRateDetails(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms, this.state.totalAdult, this.state.totalChildren,
                      //ratePlanCode, roomCode, this.state.specialCodeType, this.state.specialCode);
                  this.props.roomRateDetails(checkInDate, checkOutDate, totalRooms, totalAdult, totalChildren,
                      ratePlanCode, roomCode, specialCodeType, specialCode);

                  this.setState({ ratePlanCode: ratePlanCode, roomCode: roomCode, roomName: roomName, specialRequests: specialRequests, isProcessBooking: true,
                    ratePolicy: ratePolicy, ratePlanName: ratePlanName });
              }
          });
      }
      else {
          //this.props.roomRateDetails(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms, this.state.totalAdult, this.state.totalChildren,
              //ratePlanCode, roomCode, this.state.specialCodeType, this.state.specialCode);
          this.props.roomRateDetails(checkInDate, checkOutDate, totalRooms, totalAdult, totalChildren,
              ratePlanCode, roomCode, specialCodeType, specialCode);

          this.setState({ ratePlanCode: ratePlanCode, roomCode: roomCode, roomName: roomName, specialRequests: specialRequests, isProcessBooking: true,
              ratePlanName: ratePlanName, ratePolicy: ratePolicy });
      }
  }

  handleClick = (event) => {
    let eventParent = event.target;
    //console.log(eventParent);
        if(!event.target.className || !event.target.className.includes || !event.target.className.includes('count-person-pop-up')){
            this.showHideCountPerson(false);
        }
        if(!event.target.className || !event.target.className.includes || !event.target.className.includes('spesial-code-pop-up')){
            this.showHideSpesialCode(false);
        }
        if(!event.target.className || !event.target.className.includes || !event.target.className.includes('dropdown-toggle')){
            $('.dropdown-menu').removeClass('show');
        }
    }

    searchRoom = () => {
        //console.log(this.state.specialCode);
        if ( !this.state.isFirstLoad && ( (this.state.specialCode && !this.state.specialCodeType) ||(!this.state.specialCode && this.state.specialCodeType) ) ) {
            let condition =  $('#popup-specialcode').is(':visible');
            if(!condition){
              this.showHideSpesialCode(true);
            }
            SweetAlert.fire({icon:'warning',title:'',text:'Please select special code type',buttonsStyling: false,customClass:{confirmButton:'btn btn-success'}});
            // this.setState({specialCodeAlert:true});
            // alert("Please select special code type");
            // console.log($('#alert-pop-up').modal);
            // $('#alert-pop-up').modal('show');
        }
        else {
            localStorage.setItem(config.KEY_CHECK_IN_DATE, this.state.checkInDate);
            localStorage.setItem(config.KEY_CHECK_OUT_DATE, this.state.checkOutDate);
            localStorage.setItem(config.KEY_TOTAL_NIGHT, this.state.totalNight);
            localStorage.setItem(config.KEY_TOTAL_ROOMS, this.state.totalRooms);
            localStorage.setItem(config.KEY_TOTAL_ADULT, this.state.totalAdult);
            localStorage.setItem(config.KEY_TOTAL_CHILDREN, this.state.totalChildren);
            localStorage.setItem(config.KEY_SPECIAL_CODE, this.state.specialCode);
            localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, this.state.specialCodeType);

            if ( !this.state.isNewGST ) {
                this.props.searchRooms(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms, this.state.totalAdult, this.state.totalChildren,this.state.specialCodeType, this.state.specialCode, false);
            }
            else {
                this.props.searchRooms(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms, this.state.totalAdult, this.state.totalChildren,this.state.specialCodeType, this.state.specialCode, true);
            }

            this.showHideSpesialCode(false);

            this.setState({ isSearch: true, totalAPIRooms: 0, isNoAvailableRoom: false, isInvalidPromoCode: false });
        }
    }
  countPersonStyle = () => {
        let person = $('#person-input-container');
        // let parent = $('.banner-slider').height();
        if(person.length){
            return {
                left: isMobileView()?(-person.offset().left + 20):'0px',
                width:'400px'
            }
        }
    }
    showHideCountPerson = (status) => {
        status ? $('#count_person').show() : $('#count_person').hide();
    }
    showHideSpesialCode = (status) => {
        status ? $('#popup-specialcode').show() : $('#popup-specialcode').hide();
  }
  checkCurrentRooms = () => {
    return $('.input-spin').length;
  }
  handleDeleteRoom = (target) => {
    $(`#${target}`).remove();
    this.setPersonCount();
    this.forceUpdate();
    this.setState({totalRooms:this.state.totalRooms - 1});
  }
    NewRoom = ({currentNumber}) => {
        return(
            <div className={`row input-spin count-person-pop-up`} id={`input-spin-${currentNumber}`}>
                <div className="col-3 col-lg-3 count-person-pop-up">
                    <span className="room count-person-pop-up room-title">Room {currentNumber}</span>
                </div>
                <div className="col-4 col-lg-4 count-person-pop-up">
                    <div className="input-group input-group-sm count-person-pop-up">
                        <div className="input-group-prepend count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-${currentNumber}`,`input-children-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                        </div>
                        <input onChange={()=>{}} defaultValue='1' id={`input-adults-${currentNumber}`} className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                        <div className="input-group-append count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-${currentNumber}`,`input-children-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-lg-4 count-person-pop-up">
                    <div className="input-group input-group-sm count-person-pop-up">
                        <div className="input-group-prepend count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-${currentNumber}`,`input-adults-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                        </div>
                        <input onChange={()=>{}} id={`input-children-${currentNumber}`} defaultValue='' placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                        <div className="input-group-append count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-${currentNumber}`,`input-adults-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                        </div>
                    </div>
                </div>
        <div className='col-1 room-delete-btn col-lg-1 count-person-pop-up'>
          <div onClick={()=>this.handleDeleteRoom(`input-spin-${currentNumber}`)} className='count-person-pop-up' style={{width:20,height:20,borderRadius:10,borderColor: 'red',fontWeight:'bold',color:'red',marginTop:8,position:'relative',left:-4,cursor:'pointer'}}>X</div>
        </div>
            </div>
        )
    }
    addNewRoom = () => {
        const currentNumber = this.state.totalRooms+1;
        let parent = document.getElementById('row-room');
        let newDiv = document.createElement('div');
        ReactDOM.render(<this.NewRoom currentNumber={currentNumber}/>, newDiv);
        parent.appendChild(newDiv);
        this.setState({totalRooms:currentNumber},this.setPersonCount);
    }
    setPersonCount = () => {
    let adults = 0,children = 0, resultString = '';
        $('.input-adults').each((index,element)=>{
            adults += element.value ? parseInt(element.value) : 0;
        });
        $('.input-children').each((index,element)=>{
            children += element.value ? parseInt(element.value) : 0;
        });

        this.setState({ totalAdult: adults, totalChildren: children });
        //${adults>1?'s':''}
        // if(adults){
        //     resultString += `${adults} Adult `;
        // }
        // if(children){
        //     resultString += `${children} Children`;
    // }
    let totalRooms = 0;
    $('.input-room').each((index,element)=>{
            totalRooms += element.value ? parseInt(element.value) : 0;
        });
    resultString = `${totalRooms} Room${totalRooms>1?'s':''}, ${adults} Adult${adults>1?'s':''}, ${children} Child${children<=1?'':'ren'}`;
    // $('#persons').val(resultString);
    this.setState({persons:resultString, totalRooms: totalRooms});
  }
  checkCurrentChildrenCount = () => {
    let children = 0;
    $('.input-children').each((index,element)=>{
            children += element.value ? parseInt(element.value) : 0;
    });
    return children;
  }
  handleRoomChanged = (type,index) => {
    let target = $(`#${"input-room-" + index}`);
    let room = target.val() ? parseInt(target.val()) : 0;

    if(type === COUNT_PERSON.PLUS){
            if((room < config.MAX_ROOM)){
                target.val(room+1);

        /* $("#input-adults-" + index).val("1");
        $("#input-children-" + index).val("0"); */

        this.setPersonCount();
            }
        }else if(type === COUNT_PERSON.MIN){
      if(room <= 1){
        target.val('1');
      }else if(room){
        target.val(room-1);

        /* $("#input-adults-" + index).val("1");
        $("#input-children-" + index).val("0"); */

        this.setPersonCount();
      }
    }



  }
    handleCountPerson = (type,targetId,neighborId) => {
    let target = $(`#${targetId}`);
    let neighbor = $(`#${neighborId}`);

    let roomId = targetId.split("-");
    let room = $(`#${"input-room-" + roomId[2]}`);
    let situation = target.val() ? parseInt(target.val()) : 0;
    let situationNeighbor = neighbor.val() ? parseInt(neighbor.val()) : 0;
    let situationRoom = room.val() ? parseInt(room.val()) : 0;
        if(type === COUNT_PERSON.PLUS){
            /* if (situation!==100 && (situation+situationNeighbor < (situationRoom * config.MAX_ADULT_CHILDREN))){ */
            if (situation!==100 && (situation+situationNeighbor < config.MAX_ADULT_CHILDREN)){
                target.val(situation+1);
            }
        }else if(type === COUNT_PERSON.MIN){
      if(targetId.includes('adults')){
        if(situation <= 1){
          target.val('1');
        }else if(situation){
          target.val(situation-1);
        }
      }else{
        if(situation <= 1){
          target.val('0');
        }else if(situation){
          target.val(situation-1);
        }
      }
    }
    this.setPersonCount();
        this.forceUpdate(()=>{console.log('already doing forceUpdate')});
    }
    dataHasChanges = () => {
        let checkInDate = _getLocalStorage(config.KEY_CHECK_IN_DATE);
        let checkOutDate = _getLocalStorage(config.KEY_CHECK_OUT_DATE);
        let totalNight = _getLocalStorage(config.KEY_TOTAL_NIGHT);
        let totalRooms = _getLocalStorage(config.KEY_TOTAL_ROOMS);
        let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
        let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
        let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);
        let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);

        let hasChanges = false;
        if ( this.state.checkInDate && checkInDate && this.state.checkInDate.toString() !== checkInDate.toString() ) {
            hasChanges = true;
        }
        if ( this.state.checkOutDate && checkOutDate && this.state.checkOutDate.toString() !== checkOutDate.toString() ) {
            hasChanges = true;
        }
        if ( this.state.totalRooms && totalRooms && this.state.totalRooms.toString() !== totalRooms.toString() ) {
            hasChanges = true;
        }
        if ( this.state.totalAdult && totalAdult && this.state.totalAdult.toString() !== totalAdult.toString() ) {
            hasChanges = true;
        }
        if ( this.state.totalChildren && totalChildren && this.state.totalChildren.toString() !== totalChildren.toString() ) {
            hasChanges = true;
        }
        if ( this.state.specialCodeType && specialCodeType && this.state.specialCodeType.toString() !== specialCodeType.toString() ) {
            if ( specialCodeType === "" ) {
                hasChanges = true;
            }
            else {
                hasChanges = true;
            }
        }
        if ( this.state.specialCode && specialCode && this.state.specialCode.toString() !== specialCode.toString() ) {
            if ( specialCodeType === "" ) {
                hasChanges = true;
            }
            else {
                hasChanges = true;
            }
        }

        return hasChanges;
    }
    toggleDropDown = () => {
        $('.dropdown-menu').addClass('show')
    }
    handleDateRangePickerApply = (event, picker, target) => {
    let { startDate } = picker;
    let { endDateRangePickerValue, startDateRangePickerValue } = this.state;
    if(target==='start'){
      let condition = endDateRangePickerValue.diff(startDate,'day');
      if(condition>0){
        let startDateString = startDate.format('DD MMM YYYY');
        let totalNight = endDateRangePickerValue.diff(startDate,'day');
        this.setState({startDateString,totalNight,checkInDate: startDate.format('YYYY-MM-DD'),startDateRangePickerValue: startDate, endDateMinDate: moment(startDate.format('YYYY-MM-DD')).add(1,'day')});
      }else{
        let startDateString = startDate.format('DD MMM YYYY');
        let newEndDate = moment(startDateString).add(1,'day');
        let endDateString = newEndDate.format('DD MMM YYYY');
        let checkOutDate = newEndDate.format('YYYY-MM-DD');
        let endDateRangePickerValue = newEndDate;
        let totalNight = 1;
        this.setState({startDateString,totalNight,checkInDate: startDate.format('YYYY-MM-DD'),startDateRangePickerValue: startDate, endDateMinDate: moment(startDate.format('YYYY-MM-DD')).add(1,'day'),endDateString,checkOutDate,endDateRangePickerValue});
      }
    }else if(target==='end'){
      let endDateString = startDate.format('DD MMM YYYY');
      let totalNight = startDate.diff(startDateRangePickerValue,'day');
      this.setState({endDateString,totalNight,checkOutDate: startDate.format('YYYY-MM-DD'),endDateRangePickerValue:startDate});
    }
        // let { startDate, endDate } = picker;
        // let startDateString = startDate.format('DD MMM YYYY');
        // let endDateString = endDate.format('DD MMM YYYY');
        // let totalNight = endDate.diff(startDate,'day');//console.log("start2=" + startDate.format('YYYY-MM-DD')+", end2=" +endDate.format('YYYY-MM-DD'));
        // this.setState({startDateString,endDateString,totalNight, checkInDate: startDate.format('YYYY-MM-DD'), checkOutDate: endDate.format('YYYY-MM-DD'), startDateRangePickerValue: moment(startDate), endDateRangePickerValue: moment(endDate)});
    }
  openDetail = (target) => {
    if(isMobileView()){
      let clickedTarget = this.state[target];
      let jqueryTarget = `.${target}`;
      clickedTarget ? $(jqueryTarget).hide() : $(jqueryTarget).show();
      this.setState({[target]:!clickedTarget});
    }
  }

  setShowAll = () => { this.setState({ isShowAll: true }); $("#show_more").hide(); }

  showPercentage(percentage) {
      let roundDown = Math.floor(percentage);
      let decimal = percentage - roundDown;

      return decimal > 0.49999999999 ? roundDown + 1 : roundDown;
  }

  loopRatePackages = (packages) => {
      let dataToReturn = [];
      for ( let packageCode in packages ) {
          let pack = packages[packageCode];

          dataToReturn.push(
              <div key={packageCode} className="text-green">Inclusive of { pack.name }</div>
          );
      }

      return dataToReturn;
  }

  loopRateData = (roomCode, rates, specialRequests, roomName) => {
      let dataToReturn = [];
      let descriptionCount = 1;
      let identifier = roomName.replace(/ /g, '_').replace('(', '_').replace(')', '_');
      for ( let rateCode in rates ) {
          let rate = rates[rateCode];

          /*let description = rate.description.split('\n').map(i => {
              return <p key={descriptionCount++}>{i}</p>
          });*/

          let policyCancellationDate = '';
          if ( rate.policy ) {
              if ( rate.policy.cancellation_days > 0 ) {
                  policyCancellationDate = moment(this.state.checkInDate).add(-1 * rate.policy.cancellation_days, 'days').format('ddd, DD MMM YYYY');
              }
          }

          let lowestRate = 100000;
          dataToReturn.push(
            <div key={rateCode} className="row detail">
              <div className="modal fade popUpModel" id={identifier+rateCode} tabIndex="-1" role="dialog" aria-hidden="true">
                <div style={{maxWidth:800}} className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="modal-header">
                        <h4 className="modal-title">{ rate.name }</h4>
                        <button type="button" className="close" data-dismiss="modal">&#10005;</button>
                      </div>
                      <div style={{padding:20}}>
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rate.description) }}></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="plan">
                  { isMobileView() ?
                    <h5 className="text-primary mb-0 plan_name" data-toggle="modal" data-target={`#${identifier+rateCode}`}>
                      <strong>{ rate.name }</strong>
                      <div className="d-inline-block text-grey show-mobile plan-info">
                        <FontAwesomeIcon icon="info-circle"/>
                      </div>
                    </h5>
                    :
                    <h5 className="text-primary mb-0 plan_name">
                      <strong>{ rate.name }</strong>
                    </h5>
                  }
                  { rate.isMemberOnly ? <div className="options"><small className="text-primary"><strong>(Member Exclusive)</strong></small></div> : null }

                  { rate.policy ?
                    (rate.policy.is_non_refundable ? <div className="text-green policy"><strong>{rate.policy.name}</strong></div> :
                      <div className="policy"><div className="text-green"><strong>{ rate.policy.name }</strong></div><p>Before { policyCancellationDate } (S$ { rate.policy.penalty } admin fee per room applies)</p></div>)
                    : null }

                  {/* this.state.isLongTermCode ? <div className=""><div className="rooms">{ rate.unit } Room{ rate.unit > 1 ? 's' :'' } left</div></div> : null */}

                  { rate.packages ? <div className="rate-packages">{ this.loopRatePackages(rate.packages) }</div> : null }

                  <div className="description mt-2 hide-mobile">
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rate.description) }}></p>
                  </div>
                </div>
              </div>
              <div className="col md-5">
                <div className="price">
                  <p className="mb-0 hide-mobile">From</p>
                  { rate.discountPercentage > 0 ?
                  <p>
                    <strong className="hide-mobile">
                      <strike className="price_before">S$ { rate.baseRate }</strike>
                      &nbsp;
                      <span className="text-danger discount">{ this.showPercentage(rate.discountPercentage) }%Off</span>
                    </strong>
                  </p> : null }
                  <div className="total_price hide-mobile">
                    S$ { rate.rate }
                  </div>
                  { isMobileView() ? null : <p className="average-per-night-text">Average per Night</p> }
                  <div className="book-container">
                    {/* rate.discountPercentage > 0 ? <p className="book_now">Book now & save { parseInt(rate.discountPercentage) }%</p> : null */}
                    {/*<p className="guests">Cost for 1 night for { this.state.totalAdult + this.state.totalChildren } Guests</p>*/}
                    
                    {/*<button onClick={(e)=>this.addReservation(roomCode, rateCode, specialRequests, roomName, rate.name, rate.policy)}
                      style={{ backgroundColor: "#FFF", color: "#000", fontWeight: "normal" }}
                      className="btn btn-sm btn-success btn-square book">
                      Get Free WWW Ticket and more
                    </button>

                    <br /><br />*/}

                    <button onClick={(e)=>this.addReservation(roomCode, rateCode, specialRequests, roomName, rate.name, rate.policy)} className="btn btn-sm btn-success btn-square book">
                      { rate.discountPercentage > 0 ? <strike className="price_before show-mobile">S$ { rate.baseRate }</strike> : null }
                      <div className="total_price show-mobile">S$ { rate.rate }</div>BOOK
                    </button>

                    
                  </div>
                  { isMobileView() ? <p className="average-per-night-text">Average per Night</p> : null }
                </div>
              </div>
            </div>
          );
      }

      return dataToReturn;
  }

    loopRoomFeatures = (roomFeatures) => {
      let dataToReturn = [];
      let count = 0;
      let temp = [];
      for ( let roomFeatureId in roomFeatures ) {
        let roomFeature = roomFeatures[roomFeatureId];

        // Exclude adult children and no smoking icon
        if ( roomFeature.room_feature_name.toLowerCase().indexOf("adults") === -1
            && roomFeature.room_feature_name.toLowerCase().indexOf("no bbq pit") === -1 && roomFeature.room_feature_name.toLowerCase().indexOf("barbeque pit") === -1 ) {
          dataToReturn.push(
            <div key={count++} className="facility-item">
              <ImgWithTooltip imgSrc={roomFeature.room_feature_icon} key={roomFeatureId} tooltipTitle={roomFeature.room_feature_name}/>
            </div>
          );
        }
      }

      return dataToReturn;
    }

    loopContentData = () => {
      if ( !this.state.rooms ) return null;

      let limit = this.state.isShowAll ? this.state.totalAPIRooms : config.FIRST_LOAD_TOTAL_ROOMS;
      let count = 1;
      let dataToReturn = [];

      for ( let roomCode in this.state.rooms ) {
          let roomData = this.state.rooms[roomCode];

          // Prepare adult children and non-smoking icon
          let iconAdultChildren = '';
          let iconAdultChildrenName = '';
          let iconNonSmoking = '';
          let iconNonSmokingName = '';
          let iconBBQ = '';
          let iconBBQName = '';
          let iconNoBBQ = '';
          let iconNoBBQName = '';
          for ( let key in roomData.features ) {
              if ( roomData.features[key].room_feature_name.toLowerCase().indexOf("adults") > -1 ) {
                  iconAdultChildren = roomData.features[key].room_feature_icon;
                  iconAdultChildrenName = roomData.features[key].room_feature_name;
              }
              /*else if ( roomData.features[key].room_feature_name.toLowerCase().indexOf("smoking") > -1 ) {
                  iconNonSmoking = roomData.features[key].room_feature_icon;
                  iconNonSmokingName = roomData.features[key].room_feature_name;
              }*/
              else if ( roomData.features[key].room_feature_name.toLowerCase().indexOf("barbeque pit") > -1 ) {
                  iconBBQ = roomData.features[key].room_feature_icon;
                  iconBBQName = roomData.features[key].room_feature_name;
              }
              else if ( roomData.features[key].room_feature_name.toLowerCase().indexOf("no bbq pit") > -1 ) {
                  iconNoBBQ = roomData.features[key].room_feature_icon;
                  iconNoBBQName = roomData.features[key].room_feature_name;
              }
          }
          //console.log(roomData);
          let target = count;

          let lowestRate = [0.0];
          if ( roomData.lowest_rate.toString().indexOf(".") > -1 ) {
              lowestRate = roomData.lowest_rate.split(".");
          }
          else {
            lowestRate = [roomData.lowest_rate, '00'];
          }

          // set images for the room
          let mainDisplay = roomData.galleries[0].url;
          let mainDisplayTitle = roomData.galleries[0].title;

          let images = []; let imageCaptions = [];

          for ( let index = 0; index < roomData.galleries.length; index++ ) {
              images.push(roomData.galleries[index].url);
              imageCaptions.push(roomData.galleries[index].title);
          }

          let descriptionCount = 1;
          /*let description = roomData.description.split('\n').map(i => {
              return <p key={descriptionCount++}>{i}</p>
          });*/
          let description = roomData.description;

        dataToReturn.push(
          <div key={target} className="prop room-list-details" data-prop-id={target}>
          <div className="modal fade popUpModel" id={roomCode+dataToReturn.length} tabIndex="-1" role="dialog" aria-hidden="true">
            <div style={{maxWidth:800}} className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="modal-header">
                    <h4 className="modal-title">{ roomData.name }</h4>
                    <button type="button" className="close" data-dismiss="modal">&#10005;</button>
                  </div>
                  <div style={{padding:20}} dangerouslySetInnerHTML={{ __html: this.ATarget(DOMPurify.sanitize(description)) }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 prop-name={target}>{ roomData.name }</h2>
          {
            this.state['image'+target] ?
            <LightboxComponent images={ images } imageCaptions={ imageCaptions } closeLigthbox={()=>this.setState({['image'+target]:false})}/> :
            null
          }
          <div className="row">
            <div className="col-md-4">
            <img onClick={()=>this.setState({['image'+target]:true})} alt={mainDisplayTitle} title={mainDisplayTitle} className="img-fluid room-list-image" style={{cursor:'pointer'}} src={mainDisplay}/>
              {
                isMobileView() ?
                <div className='row'>
                    <div className='col-12 col-md-12'>
                        <div style={{ paddingTop: '5px',paddingBottom:10, }}>
                          <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={require('../../assets/img/icon/amenity-sub-27df0737a71dd6b3eb1bcff0100d1ae25.png')} keys={target} tooltipTitle='Room Size'/>
                          <span style={{marginLeft:10}}>
                            { roomData.roomSize }m<sup>2</sup>
                          </span>

                          { roomData.bedType != null ?
                          <span style={{marginLeft:10}}>
                              |&nbsp;&nbsp;{ roomData.bedType }
                          </span> : null }

                          <span style={{marginLeft:10}}>
                              |
                              <span data-toggle="modal" data-target={`#${roomCode+dataToReturn.length}`} style={{marginLeft:10}}>
                                  <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={MyAssets.icon.info} keys={target} tooltipTitle={ 'Detail' } />
                              </span>
                          </span>
                        </div>
                    </div>
                    <div className='col-12 col-md-12'>
                        { iconAdultChildren != "" ?

                        <div className="icon-container" style={{marginBottom:10}}>
                          <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconAdultChildren} keys={target} tooltipTitle={ iconAdultChildrenName } />
                          <div className="icon-detail">
                            {iconAdultChildrenName}
                          </div>
                        </div>
                          : null }

                        { /*iconNonSmoking != "" ?

                        <div className="icon-container" style={{marginBottom:10}}>
                            
                          <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconNonSmoking} keys={target} tooltipTitle={ iconNonSmokingName } />
                          <div className="icon-detail">{iconNonSmokingName}</div>
                        </div>
                          : null */}

                        { iconBBQ != "" ?

                        <div className="icon-container" style={{marginBottom:10}}>
                            
                          <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconBBQ} keys={target} tooltipTitle={ iconBBQName } />
                          <div className="icon-detail">{iconBBQName}</div>
                        </div>
                          : null}

                        { iconNoBBQ != "" ?

                        <div className="icon-container" style={{marginBottom:10}}>
                            
                          <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconNoBBQ} keys={target} tooltipTitle={ iconNoBBQName } />
                          <div className="icon-detail">{iconNoBBQName}</div>
                        </div>
                          : null}

                        <div style={{borderBottom: '1px solid #eee',marginBottom: 10}}></div>

                        <div className="facility-item-container">
                            {this.loopRoomFeatures(roomData.features)}
                        </div>
                    </div>
                    {/* !this.state.isLongTermCode && roomData.unit <= config.ROOMS_LEFT_LIMIT ? <div className="rooms">{ roomData.unit } Room{ roomData.unit > 1 ? 's' :'' } left</div> : null */}
                    <div onClick={()=>this.openDetail(`data-isOpen-${target}`)} className={`col-12 col-md-12 price-container${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>
                      <div className="price-content">
                        <h6>From</h6>
                        <h3 className={`start-from${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>S$ {_formatAmount(lowestRate[0])}.{lowestRate[1]}</h3>
                      </div>
                    </div>
                </div>
                :
                <div className='row'>
                    <div style={{ paddingTop: '5px', paddingLeft:15, paddingRight:15, width: '100%' }}>
                      <div onClick={()=>this.openDetail(`data-isOpen-${target}`)} className={`col-6 col-md-12 price-container${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>
                        <div className="rate-container">
                          <h6>From</h6>
                          <h3 className={`start-from${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>S$ {_formatAmount(lowestRate[0])}.{lowestRate[1]}</h3>
                          {/* ( !this.state.isLongTermCode && roomData.unit <= config.ROOMS_LEFT_LIMIT ) ? <div className="rooms">{ roomData.unit } Room{ roomData.unit > 1 ? 's' :'' } left</div> : null */}
                        </div>
                      </div>

                      <div className="icon-container" style={{ marginBottom: 10 }}>
                        <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={require('../../assets/img/icon/amenity-sub-27df0737a71dd6b3eb1bcff0100d1ae25.png')} keys={target} tooltipTitle='Room Size'/>
                        <div className="icon-detail">
                          { roomData.roomSize }m<sup>2</sup>

                          { roomData.bedType != null ?
                          <span style={{marginLeft:10}}>
                              |&nbsp;&nbsp;{ roomData.bedType }
                          </span> : null }

                          <span style={{marginLeft:10}}>
                            |
                            <span data-toggle="modal" data-target={`#${roomCode+dataToReturn.length}`} style={{marginLeft:10}}>
                                <ImgWithTooltip containerStyle={{display:'inline-block', cursor: 'pointer'}} imgSrc={MyAssets.icon.info} keys={target} tooltipTitle={ 'Click to view Detail' } />
                            </span>
                          </span>
                        </div>                        
                      </div>

                      { iconAdultChildren != "" ?

                      <div className="icon-container" style={{marginBottom:10}}>
                        <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconAdultChildren} keys={target} tooltipTitle={ iconAdultChildrenName } />
                        <div className="icon-detail">
                          {iconAdultChildrenName}
                        </div>
                      </div>
                        : null }

                      { /*iconNonSmoking != "" ?

                      <div className="icon-container" style={{marginBottom:10}}>
                          
                        <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconNonSmoking} keys={target} tooltipTitle={ iconNonSmokingName } />
                        <div className="icon-detail">{iconNonSmokingName}</div>
                      </div>
                        : null */}

                      { iconBBQ != "" ?

                      <div className="icon-container" style={{marginBottom:10}}>
                          
                        <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconBBQ} keys={target} tooltipTitle={ iconBBQName } />
                        <div className="icon-detail">{iconBBQName}</div>
                      </div>
                        : null}

                      { iconNoBBQ != "" ?

                      <div className="icon-container" style={{marginBottom:10}}>
                          
                        <ImgWithTooltip containerClassName="icon" containerStyle={{display:'inline-block'}} imgSrc={iconNoBBQ} keys={target} tooltipTitle={ iconNoBBQName } />
                        <div className="icon-detail">{iconNoBBQName}</div>
                      </div>
                        : null}

                      <div style={{borderBottom: '1px solid #eee',marginBottom: 10}}></div>
                    </div>
                    <div className='col-6 col-md-12 order-first order-lg-2'>
                        <div className="facility-item-container">
                            {this.loopRoomFeatures(roomData.features)}
                        </div>
                    </div>
                </div>
              }
            </div>
            <div className={`col-md-8 data-isOpen-${target}`}>
            <div className="">
              {/*<div className="row d-none d-md-flex">
              <div className="col-md-7">
                <p><strong>Rate Plan</strong></p>
              </div>
              <div className="col-md-5">
                <p className="text-right"><strong>Average per Night</strong></p>
              </div>
              </div>*/}
              { this.loopRateData(roomCode, roomData.rates, roomData.specialRequests, roomData.name) }
            </div>
            </div>
          </div>
          </div>
        );

        if ( ++count > limit ) break;
      }
      return dataToReturn;
    }

    componentWillReceiveProps(nextProps) {
        if ( nextProps.content ) {
            localStorage.setItem(config.KEY_CONTENT, JSON.stringify(nextProps.content));

            if ( nextProps.content.main ) {
              this.setState({ maintenanceMessage: nextProps.content.main });
            }
        }

        // No result
        if ( ( this.state.isSearch && nextProps.rooms && nextProps.rooms.status ) || ( nextProps.rooms && nextProps.rooms.status && !this.state.rooms ) ) {
            if ( nextProps.rooms.data.error ) {
                if ( nextProps.rooms.data.message.toLowerCase() === "invalid promotion code" ) {
                    this.setState({ isInvalidPromoCode: true });
                }
                else {
                    this.setState({ isNoAvailableRoom: true });
                }
                this.setState({ isSearch: false, isShowAll: false, totalAPIRooms: 0, rooms: null });

                $("#show_more").hide();
             }
             // Show more
             else if ( nextProps.rooms.data != "[]" && !(nextProps.rooms.data === undefined || nextProps.rooms.data.length == 0) ) {
                let totalRooms = 0;
                for ( let roomCode in nextProps.rooms.data ) {
                    totalRooms++;
                }

                //console.log(nextProps.rooms.data);

                this.setState({ isInvalidPromoCode: false, isNoAvailableRoom: false, rooms: nextProps.rooms.data, isSearch: false, isShowAll: false, totalAPIRooms: totalRooms,
                    isLongTermCode: this.state.specialCodeType == "Long Term Code" ? true : false });

                if ( totalRooms > config.FIRST_LOAD_TOTAL_ROOMS ) {
                    $("#show_more").show();
                }
                else {
                  $("#show_more").hide();
                }
            }
            else {
                $("#show_more").hide();
                this.setState({ isInvalidPromoCode: false, isNoAvailableRoom: true, isSearch: false, isShowAll: false, totalAPIRooms: 0, rooms: null });
            }
        }

        if ( this.state.isProcessBooking && nextProps.roomRateDetail ) {
            if ( nextProps.roomRateDetail.totalRoomRateAndPackages ) {
                localStorage.setItem(config.KEY_ROOM_NAME, this.state.roomName);
                localStorage.setItem(config.KEY_ROOM_CODE, this.state.roomCode);
                localStorage.setItem(config.KEY_RATE_CODE, this.state.ratePlanCode);
                localStorage.setItem(config.KEY_SPECIAL_REQUESTS, JSON.stringify(this.state.specialRequests));

                let roomRateDetail = nextProps.roomRateDetail;

                localStorage.setItem(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES, roomRateDetail.totalRoomRateAndPackages);
                localStorage.setItem(config.KEY_TOTAL_TAXES_AND_FEES, roomRateDetail.totalTaxesAndFees);
                localStorage.setItem(config.KEY_TAX_INCLUSIVE, roomRateDetail.taxInclusive);
                localStorage.setItem(config.KEY_GRAND_TOTAL, roomRateDetail.grandTotal);
                localStorage.setItem(config.KEY_CHARGE_BREAKDOWN, JSON.stringify(roomRateDetail.charges));
                localStorage.setItem(config.KEY_ACCESS_CODE, nextProps.accessToken);

                let packages = nextProps.packages;
                localStorage.setItem(config.KEY_ADDONS, JSON.stringify(packages));

                localStorage.removeItem(config.KEY_INCLUSIVE_PACKAGE);
                if ( nextProps.roomRateDetail.packages ) {
                    localStorage.setItem(config.KEY_INCLUSIVE_PACKAGE, JSON.stringify(nextProps.roomRateDetail.packages));
                }

                // Set authorised domain and authorised email
                localStorage.setItem(config.KEY_AUTHORISED_DOMAIN, JSON.stringify(roomRateDetail.authorised_domain));

                // Store policy info for T&C
                localStorage.setItem(config.KEY_POLICY, JSON.stringify(this.state.ratePolicy));

                // Store Rate Plan Name for T&C
                localStorage.setItem(config.KEY_SELECTED_RATE, this.state.ratePlanName);

                // Store whether booker needs to pay for Addon
                localStorage.setItem(config.KEY_PAY_FOR_ADDON, nextProps.rooms.payAddon);

                // Store GST percentage
                localStorage.setItem(config.KEY_GST_PERCENTAGE, nextProps.gst);

                //console.log(packages);
                let updateToken = _getLocalStorage(config.KEY_UPDATE_TOKEN);
                let modifyBookingID = _getLocalStorage(config.KEY_UPDATE_BOOKING_ID);

                if ( updateToken && modifyBookingID ) {
                    // console.log("REMOVE token and modify Reservation ID");
                    localStorage.setItem(config.KEY_TEMP_MODIFY_BOOKING_DATA, modifyBookingID + ";" + updateToken);

                    modifyBookingID = updateToken = undefined;
                    localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);
                    localStorage.removeItem(config.KEY_UPDATE_TOKEN);
                    // localStorage.removeItem(config.KEY_MODIFY_BOOKING_HAS_LOADED);
                }

                /*let hasEnoughQty = false;
                if ( packages && Object.keys(packages).length > 0 ) {
                    // check whether packages have enough qty
                    for ( let packageDate in packages ) {
                        if ( packages[packageDate].qty > this.state.totalRooms ) {
                            hasEnoughQty = true;
                        }
                    }
                }*/
                let isShowingAddOn = false;
                if ( packages && Object.keys(packages).length > 0 ) {
                    isShowingAddOn = true;
                }

                // remove bbq pit information
                localStorage.removeItem(config.KEY_TOTAL_PACKAGES_AMOUNT);
                localStorage.removeItem(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
                localStorage.removeItem(config.KEY_SELECTED_ADDON);
                localStorage.removeItem(config.KEY_ADDON_CHARGES);
                if ( isShowingAddOn ) {
                    this.props.history.push('/addons');
                }
                else {
                    this.props.history.push('/book');
                }
            }
            else {
                this.setState({ isProcessBooking: false });
            }
        }
    }
  reFormatRoomData = () => {
    let target = $('.room-title');
    target.each((index,element)=>{
      element.innerHTML = `Room ${index+1}`;
    });
    let deleteBtn = $('.room-delete-btn');
    if(deleteBtn.length===1){
      deleteBtn.each((index,val)=>{
        val.hidden = true;
      })
    }else{
      deleteBtn.each((index,val)=>{
        val.hidden = false;
      })
    }
  }
  getKeyDownEvent = (e) => {
    if(e.target.value && e.keyCode===13){
      this.showHideSpesialCode(false);
      this.searchRoom();
    }
  }
  checkingClearButtonRequire = () => {
    if(this.state.specialCodeType || this.state.specialCode){
      return true
    }else{
      return false
    }
  }

    handleCancelModifying() {
        localStorage.removeItem(config.KEY_UPDATE_TOKEN);
        localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);

        //this.props.history.push('/');
        window.location.href = window.location.href;
    }

    ATarget(text) {
      text = text.replace(/<a/g, "<a target='_blank'");
      return text;
    }

    render(){

      // console.log(this.state.updateToken + " " + this.state.modifyBookingID);
    //console.log("start=" + moment(this.state.startDateRangePickerValue).format("YYYY-MM-DD")+", end=" + moment(this.state.endDateRangePickerValue).format("YYYY-MM-DD"));console.log("start 3=" + this.state.checkInDate+", end 3=" +this.state.checkOutDate);
    //console.log("specialCode="+this.state.specialCode);console.log("specialCodeType="+this.state.specialCodeType);
    this.reFormatRoomData();
        return(
      <div>
        { ( this.state.isSearch || this.state.isProcessBooking ) ? _loading() : ""}
        <div id="main">
          <section className="banner-slider wrap">
            <div className="banner-slider-inner">
              <div className="jumbotron jumbotron-fluid">
                <div className="jumbotron-background" style={{backgroundImage:`url(https://cdn-dresort.aidencreative.com${BackgroundImage})`}}>
                </div>
              </div>
              <div className="static">
                <img src={`https://cdn-dresort.aidencreative.com`+BackgroundImage} alt="" style={{height:'auto',width:'100%',marginLeft:'0px'}}/>
              </div>
            </div>
            <div id="header-filter">
              <div className="container">
                <div className="row">

                  <div className="col-6 col-md-2">
                    <span className="input-group input-group-light-prepend">
                      <div className="input-group-prepend">
                        <button className="btn bg-transparent" type="button" onClick={() => { $("#date-start").click(); }}>
                        <i className="icon light-calendar"></i>
                        </button>
                      </div>
                      <DateRangePicker autoApply={true} startDate={this.state.startDateRangePickerValue} autoclose={true} singleDatePicker={true} locale={{format:'DD MMM YYYY'}} minDate={DATE_NOW_STRING} onApply={(event,picker)=>this.handleDateRangePickerApply(event,picker,'start')} containerStyles={{position:'absolute',width:'100%'}}>
                        <input value={this.state.startDateString} onChange={()=>{}} type="text" style={{height:'calc( 1.8em + 1.5em + 2px',fontWeight:'600',fontSize:'0.9em',border:'none',borderRadius:0,paddingLeft:isMobileView()?'6px':'50px'}} className="form-control date-picker" id="date-start" placeholder="Check In Date"
                        autoComplete="off"/>
                      </DateRangePicker>
                    </span>
                  </div>

                  <div id="count_days"> <span>{this.state.totalNight} Night{this.state.totalNight>1?'s':''}</span> </div>

                  <div className="col-6 col-md-2">
                    <span className="input-group input-group-light-prepend">
                      <div className="input-group-prepend right">
                        <button className="btn bg-transparent " type="button" onClick={() => { $("#date-end").click(); }}>
                        <i className="icon light-calendar"></i>
                        </button>
                      </div>
                      <DateRangePicker autoApply={true} startDate={this.state.endDateRangePickerValue} autoclose={true} singleDatePicker={true} locale={{format:'DD MMM YYYY'}} minDate={this.state.endDateMinDate} onApply={(event,picker)=>this.handleDateRangePickerApply(event,picker,'end')} containerStyles={{width:'100%'}}>
                      <input value={this.state.endDateString} onChange={()=>{}} type="text" style={{height:'calc( 1.8em + 1.5em + 2px',fontWeight:'600',fontSize:'0.9em',border:'none',borderRadius:0,paddingLeft:'40px'}} className="form-control date-picker" id="date-end" placeholder="Check Out Date"
                        autoComplete="off"/>
                      </DateRangePicker>
                    </span>
                  </div>

                  <div id='person-input-container' className="col-md-3">
                    <span className="input-group input-group-light-prepend ">
                      <div className="input-group-prepend">
                        <button className="btn " type="button">
                          <i className="icon light-people"></i>
                        </button>
                      </div>
                      <input onFocus={()=>this.showHideCountPerson(true)} type="text" className="form-control count-person-pop-up" id="persons" value={this.state.persons}  onChange={()=>{}} autoComplete="off"/>
                    </span>
                    <div className="count-person-pop-up" style={this.countPersonStyle()} id="count_person">
                      <div className="container-grey count-person-pop-up">
                        <div className="container count-person-pop-up">
                          <div className="row count-person-pop-up">
                            <div className="col-4 col-lg-4 count-person-pop-up">Room(s)</div>
                            <div className="col-4 col-lg-4 count-person-pop-up">Adult(s) / Room</div>
                            <div className="col-4 col-lg-4 count-person-pop-up">Children (under 12)</div>
                            {/* <div className='col-1 col-lg-1 count-person-pop-up'></div> */}
                          </div>
                        </div>
                      </div>
                      <div className="count-person-pop-up">
                        <div className="container count-person-pop-up" id="row-room">
                          <div  id={`input-spin-1`} className="row input-spin count-person-pop-up">
                            <div className="col-4 col-lg-4 count-person-pop-up">
                              <div className="input-group input-group-sm count-person-pop-up">
                                <div className="input-group-prepend count-person-pop-up">
                                  <button onClick={()=>this.handleRoomChanged(COUNT_PERSON.MIN, '1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                                </div>
                                <input onChange={()=>{}} id="input-room-1" defaultValue={this.state.totalRooms} className="form-control input-room count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                                <div className="input-group-append count-person-pop-up">
                                  <button onClick={()=>this.handleRoomChanged(COUNT_PERSON.PLUS, '1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                                </div>
                              </div>

                            </div>
                            <div className="col-4 col-lg-4 count-person-pop-up">
                              <div className="input-group input-group-sm count-person-pop-up">
                                <div className="input-group-prepend count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                                </div>
                                <input onChange={()=>{}} id="input-adults-1" defaultValue={this.state.totalAdult} className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                                <div className="input-group-append count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-lg-4 count-person-pop-up">
                              <div className="input-group input-group-sm count-person-pop-up">
                                <div className="input-group-prepend count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                                </div>
                                <input onChange={()=>{}} id='input-children-1' defaultValue={this.state.totalChildren} placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                                <div className="input-group-append count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                                </div>
                              </div>
                            </div>
                            {/* <div className='col-1 room-delete-btn col-lg-1 count-person-pop-up'>
                              <div onClick={()=>this.handleDeleteRoom('input-spin-1')} className='count-person-pop-up' style={{width:20,height:20,borderRadius:10,borderColor: 'red',fontWeight:'bold',color:'red',marginTop:8,position:'relative',left:-4,cursor:'pointer'}}>X</div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="container-grey count-person-pop-up" style={{ 'textAlign': 'left' }}>
                        <div className="container count-person-pop-up" style={{ 'textAlign': 'left' }}>
                          {/*<strong>Maximum 4 persons in a room</strong>
                          <br />*/}
                          Multiple bookings with different pax and room types configuration should be booked separately.
                        </div>
                      </div>
                      {/* <div className="count-person-pop-up">
                        <div className="container count-person-pop-up" id="row-room">
                          <div  id={`input-spin-1`} className="row input-spin count-person-pop-up">
                            <div className="col-3 col-lg-3 count-person-pop-up">
                              <span className="room count-person-pop-up room-title">Room 1</span>
                            </div>
                            <div className="col-4 col-lg-4 count-person-pop-up">
                              <div className="input-group input-group-sm count-person-pop-up">
                                <div className="input-group-prepend count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                                </div>
                                <input onChange={()=>{}} id="input-adults-1" defaultValue="1" className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                                <div className="input-group-append count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-lg-4 count-person-pop-up">
                              <div className="input-group input-group-sm count-person-pop-up">
                                <div className="input-group-prepend count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                                </div>
                                <input onChange={()=>{}} id='input-children-1' defaultValue="" placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                                <div className="input-group-append count-person-pop-up">
                                  <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                                </div>
                              </div>
                            </div>
                            <div className='col-1 room-delete-btn col-lg-1 count-person-pop-up'>
                              <div onClick={()=>this.handleDeleteRoom('input-spin-1')} className='count-person-pop-up' style={{width:20,height:20,borderRadius:10,borderColor: 'red',fontWeight:'bold',color:'red',marginTop:8,position:'relative',left:-4,cursor:'pointer'}}>X</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="count-person-pop-up">
                        <div className="container count-person-pop-up" id="row-room">
                          <div className="row input-spin count-person-pop-up">
                            <div className="col-3 col-lg-3 count-person-pop-up">
                              <span className="room count-person-pop-up">Rooms</span>
                            </div>
                            <div className="col-4 col-lg-4 count-person-pop-up">
                              <div className="input-group input-group-sm count-person-pop-up">
                                <div className="input-group-prepend count-person-pop-up">
                                  <button onClick={()=>console.log('room min')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                                </div>
                                <input onChange={()=>{}} defaultValue="1" className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                                <div className="input-group-append count-person-pop-up">
                                  <button onClick={()=>console.log('Room Plus')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {
                        // this.checkCurrentRooms() === 5 ? null : (
                        //   <div className='count-person-pop-up'>
                        //     <div className="container count-person-pop-up" id="add-more-room">
                        //       <button onClick={this.addNewRoom} className="btn-square btn btn-sm btn-success count-person-pop-up">ADD ROOM</button>
                        //     </div>
                        //   </div>
                        // )
                      }
                      {
                        /*this.checkCurrentChildrenCount() ?
                        <div className="count-person-pop-up">
                          <div style={{textAlign:'left',paddingRight:15,paddingLeft:15}} className='container count-person-pop-up'>
                            <div className="count-person-pop-up" style={{backgroundColor:'#efefef',paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10,marginBottom:15}}>
                              Children must be under 12 years for age.<br/>
                              Special children's meal plan available at our hotels.
                            </div>
                          </div>
                        </div> : null*/
                      }
                    </div>
                  </div>

                  <div className="col-12 col-sm-3">
                    <span className="input-group input-group-light-prepend" id="special_code_wrapper">
                    <div className="input-group-prepend">
                      <button className="btn " type="button" onClick={()=>this.showHideSpesialCode(true)}>
                      <i className="icon light-tag"></i>
                      </button>
                    </div>
                    <input onFocus={()=>this.showHideSpesialCode(true)} onChange={()=>{}} value={ this.state.specialCodeText } type="text" className="form-control spesial-code-pop-up" placeholder="Special Code (Optional)" autoComplete="off"/>
                    </span>
                    <div className="spesial-code-pop-up" style={{position:'absolute',width:300,background:'white'}} id="popup-specialcode">
                      <div className="container spesial-code-pop-up">
                        <p className='spesial-code-pop-up'></p>
                        <div className='spesial-code-pop-up'>
                          <select value={ this.state.specialCodeType } className="custom-select form-sm spesial-code-pop-up" onChange={ this.handleSpecialCodeType.bind(this) }>
                            <option className='spesial-code-pop-up' val="">Select Code Type</option>
                            <option className='spesial-code-pop-up' val="Promo Code">Promo Code</option>
                            <option className='spesial-code-pop-up' val="Corporate">Corporate Code</option>
                            <option className='spesial-code-pop-up' val="Group Code">Group Code</option>
                            { config.IS_DEVELOPMENT ? <option className='spesial-code-pop-up' val="Long Term Code">Long Term Code</option> : null }
                          </select>
                        </div>
                        <div className='spesial-code-pop-up'>
                          <input onKeyDown={this.getKeyDownEvent} type="text" value={ this.state.specialCode } className="form-control custom-select form-sm spesial-code-pop-up" placeholder="Enter Code"
                            onChange={ this.handleSpecialCode.bind(this) } />
                        </div>
                        {
                          this.checkingClearButtonRequire() ?
                          <div className='spesial-code-pop-up'>
                            <button onClick={()=>{
                              this.setState({specialCode:'',specialCodeText:'',specialCodeType:''})
                            }} style={{marginBottom:20}} className="spesial-code-pop-up btn btn-sm btn-success btn-square btn-clear">Clear</button>
                          </div> : null
                        }
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-2">
                    <button className="btn btn-xl btn-success btn-square spesial-code-pop-up" onClick={ this.searchRoom.bind(this) }>SEARCH</button>
                  </div>

                </div>
              </div>
            </div>
          </section>

          { (this.state.updateToken !== null && this.state.modifyBookingID !== null) ?
            <div className="container maintenance">
              <div className="row">
                <div className="col-12 alert alert-warning mt-4">
                    <strong style={{ display: 'block' }}>Please make sure to login as a member to enjoy member privileges.</strong>
                    You're currently modifying your booking. The Reservation ID is { this.state.modifyBookingID }.
                    <br />
                    <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={ this.handleCancelModifying.bind(this) }>Cancel</a>
                </div>
              </div>
            </div>
            : null }

          { this.dataHasChanges() ?
            <div className="container maintenance">
              <div className="row">
                <div className="col-12 alert alert-danger mt-4">You have change the search criteria. Please remember to click on search button for the rate and availability.</div>
              </div>
            </div>
            : null
          }

          { this.state.maintenanceMessage !== null ?
            <div className="container maintenance">
              <div className="row">
                <div className="col-12 alert alert-info mt-4" dangerouslySetInnerHTML={{ __html: this.ATarget(DOMPurify.sanitize(this.state.maintenanceMessage)) }}></div>
              </div>
            </div>
            : null }

          <div id="book-step">
            <div className="container">
              <div className="row">
                <div id="sort"></div>
                <div className="step active" data-id="1">
                  <span style={{paddingTop:2}} className="number-count">1</span>Select Room & Dates
                </div>

                <div className="step next" data-id="2">
                  <span style={{paddingTop:2}} className="number-count">2</span>Select Add-Ons
                </div>

                <div className="step next" data-id="3">
                  <span style={{paddingTop:1}} className="number-count">3</span>Guest Information & Payment
                </div>
                <div className="step next" data-id="4">
                  <span style={{paddingTop:1}} className="number-count">4</span>Confirmation
                </div>
                {/*<div className="vertical-line">
                </div>*/}
                <div id="sort">
                  {/*<div className="btn-group position-relative" id="sort-list">
                    <button type="button" onClick={this.toggleDropDown} className="btn btn-none dropdown-toggle">Views By: <span id="sort-by">Rooms</span></button>
                    <div className="dropdown-menu" id="sort-dropdown">
                      <NavLink exact className="dropdown-item" to="/">Rooms</NavLink>
                      <NavLink exact className="dropdown-item" to="/byrates">Rates</NavLink>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>

          {isMobileView() ?
          (
            <div className="container" id="textStep">
              <div className="row">
                <p>Select Room & Dates</p>
              </div>
              {/*<div id="clonedSort">
                <div className="btn-group position-relative" id="sort-list">
                  <button type="button" onClick={this.toggleDropDown} className="btn btn-none dropdown-toggle">Views By: <span id="sort-by">Rooms</span></button>
                  <div className="dropdown-menu" id="sort-dropdown">
                    <NavLink exact className="dropdown-item" to="/">Rooms</NavLink>
                    <NavLink exact className="dropdown-item" to="/byrates">Rates</NavLink>
                  </div>
                </div>
              </div>*/}
            </div>
          ) :
          null
          }

          {/*<section id="total-reservation" className="show-mobile">
            <div className="container">
              <div className="row reservation">
                <div className="col-6 left">
                  <p className="grand-total"><span className="count">3x</span> | Grand Total: <span className="price">S$703.20</span></p>
                  <p className="link" data-toggle="modal" data-target="#reservation-modal">View/Modify</p>
                </div>
                <div className="col-6 right">
                  <p>Make this reservation</p>
                </div>
              </div>
            </div>
          </section>*/}
          <div className="container">
            <div className="row">
              <div className="col-md-12" id="lists">
                {this.loopContentData()}

                {this.state.isNoAvailableRoom ?
                <div className="no-room-available">
                    <div className="no-room-available-image">
                        <img src={NoRoom} alt="" style={{margin: '0 auto'}}/>
                    </div>
                    <div className="no-room-available-header">
                        No Room Available
                    </div>
                    <div className="no-room-available-content">
                        { this.state.specialCodeType !== "" ?
                        <p className="text-center">Please check whether you have filled-in the correct <strong>{ this.state.specialCodeType }</strong>.
                            <br />
                            You can also try to { this.state.isMember ? 'logout.' : 'login if you are an NTUC member.' }
                        </p>
                        : null }
                    </div>
                </div>
                : null}

                {this.state.isInvalidPromoCode ?
                <div className="no-room-available">
                    <div className="no-room-available-image">
                        <img src={NoRoom} alt="" style={{margin: '0 auto'}}/>
                    </div>
                    <div className="no-room-available-header">
                        Invalid Promo Code
                    </div>
                    <div className="no-room-available-content">
                        { this.state.specialCodeType !== "" ?
                        <p className="text-center">Please check whether you have filled-in the correct { this.state.specialCodeType }, <strong>{ this.state.specialCode }</strong>.
                            <br />
                            You can also try to { this.state.isMember ? 'logout.' : 'login if you are an NTUC member.' }
                        </p>
                        : null }
                    </div>
                </div>
                : null}
              </div>

            </div>
          </div>
          <div style={{textAlign:'center'}} id="show_more">
            <button className="btn btn-success btn-square" onClick={ this.setShowAll.bind(this) }>SHOW MORE</button>
          </div>
          <div className="modal fade popUpModel" id="alert-pop-up" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  Message
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { rooms: [], roomRateDetail: '', packages: [], content: '' }

    if ( state.rooms.result ) {
        params.rooms = state.rooms.result;
    }

    if ( state.roomRate.result ) {
        params.roomRateDetail = state.roomRate.result.RoomRateDetail;
        params.packages = state.roomRate.result.Packages;
        params.accessToken = state.roomRate.result.accessToken;
        params.gst = state.roomRate.result.gst;

        //console.log(state.roomRate.result);
        //console.log(params);
    }

    if ( state.content.result ) {
        params.content = state.content.result;
    }

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { searchRooms, roomRateDetails, getContent } = allAPI;
  return bindActionCreators({ searchRooms, roomRateDetails, getContent }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Home));
