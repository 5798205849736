import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages';
import './css/main.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';

import TagManager from 'react-gtm-module'

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);

const tagManagerArgs = {
    gtmId: 'GTM-N4CFVBX',
    dataLayerName: 'dataLayer'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<Provider store={store}>
	    <BrowserRouter>
	        <App />
	    </BrowserRouter>
	</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
