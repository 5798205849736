import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import MyAssets from '../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileView, ImgWithTooltip } from '../../misc/helper_functions';
import { _formatDecimalAmount } from '../../functions/helper_functions';
import { _loading } from '../../functions/message_functions';
import $ from 'jquery';
import moment from 'moment';

import BookingDetail from '../../component/BookingDetail';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TagManager from 'react-gtm-module';

class ThankYou extends React.Component{
		constructor(props){
			super(props);

			this.state={
					bookingID: this.props.match.params.id,
					confirmationNo: null,
					bookingDetail: null,
					activities: null,
					addons: null,
					isUnknownBooking: false
			}

			// Remove all local storage details
			localStorage.removeItem(config.KEY_ROOM_NAME);
			localStorage.removeItem(config.KEY_ROOM_CODE);
			localStorage.removeItem(config.KEY_RATE_CODE);
			localStorage.removeItem(config.KEY_CHECK_IN_DATE);
			localStorage.removeItem(config.KEY_CHECK_OUT_DATE);
			localStorage.removeItem(config.KEY_TOTAL_NIGHT);
			localStorage.removeItem(config.KEY_TOTAL_ROOMS);
			localStorage.removeItem(config.KEY_TOTAL_ADULT);
			localStorage.removeItem(config.KEY_TOTAL_CHILDREN);
			localStorage.removeItem(config.KEY_SPECIAL_CODE_TYPE);
			localStorage.removeItem(config.KEY_SPECIAL_CODE);
			localStorage.removeItem(config.KEY_SPECIAL_REQUESTS);

			localStorage.removeItem(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES);
			localStorage.removeItem(config.KEY_TOTAL_TAXES_AND_FEES);
			localStorage.removeItem(config.KEY_TAX_INCLUSIVE);
			localStorage.removeItem(config.KEY_GRAND_TOTAL);

			localStorage.removeItem(config.KEY_ADDONS);
			localStorage.removeItem(config.KEY_TOTAL_PACKAGES_AMOUNT);
			localStorage.removeItem(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
			localStorage.removeItem(config.KEY_SELECTED_ADDON);

			localStorage.removeItem(config.KEY_PAYMENT_TOTAL);
			localStorage.removeItem(config.KEY_PAYMENT_UNIQUE_ID);

			// Remove if there is any booking modification's data
			localStorage.removeItem(config.KEY_UPDATE_TOKEN);
			localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);
			localStorage.removeItem(config.KEY_IS_MEMBER_PREVIOUS_BOOKING);

			localStorage.removeItem(config.KEY_AUTHORISED_DOMAIN);

			let cardNo = localStorage.getItem(config.KEY_CARD_NO);
			if ( cardNo !== null ) {
				this.props.login(cardNo);
			}
			else {
				localStorage.removeItem(config.KEY_IS_MEMBER);
			}
		}

		componentDidMount() {
			window.scrollTo(0, 0);
			$("#login").hide();

			this.props.getBookingDetail(this.state.bookingID);

			// Remove all local storage details
			localStorage.removeItem(config.KEY_ROOM_NAME);
			localStorage.removeItem(config.KEY_ROOM_CODE);
			localStorage.removeItem(config.KEY_RATE_CODE);
			localStorage.removeItem(config.KEY_CHECK_IN_DATE);
			localStorage.removeItem(config.KEY_CHECK_OUT_DATE);
			localStorage.removeItem(config.KEY_TOTAL_NIGHT);
			localStorage.removeItem(config.KEY_TOTAL_ROOMS);
			localStorage.removeItem(config.KEY_TOTAL_ADULT);
			localStorage.removeItem(config.KEY_TOTAL_CHILDREN);
			localStorage.removeItem(config.KEY_SPECIAL_CODE_TYPE);
			localStorage.removeItem(config.KEY_SPECIAL_CODE);
			localStorage.removeItem(config.KEY_SPECIAL_REQUESTS);

			localStorage.removeItem(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES);
			localStorage.removeItem(config.KEY_TOTAL_TAXES_AND_FEES);
			localStorage.removeItem(config.KEY_TAX_INCLUSIVE);
			localStorage.removeItem(config.KEY_GRAND_TOTAL);

			localStorage.removeItem(config.KEY_ADDONS);
			localStorage.removeItem(config.KEY_TOTAL_PACKAGES_AMOUNT);
			localStorage.removeItem(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
			localStorage.removeItem(config.KEY_SELECTED_ADDON);

			localStorage.removeItem(config.KEY_PAYMENT_TOTAL);
			localStorage.removeItem(config.KEY_PAYMENT_UNIQUE_ID);

			// Remove if there is any booking modification's data
			localStorage.removeItem(config.KEY_UPDATE_TOKEN);
			localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);
			localStorage.removeItem(config.KEY_IS_MEMBER_PREVIOUS_BOOKING);

			localStorage.removeItem(config.KEY_AUTHORISED_DOMAIN);

			let cardNo = localStorage.getItem(config.KEY_CARD_NO);
			if ( cardNo !== null ) {
				this.props.login(cardNo);
			}
			else {
				localStorage.removeItem(config.KEY_IS_MEMBER);
			}
		}

		componentWillReceiveProps(nextProps) {
				if ( nextProps.bookingDetail ) {
						console.log(nextProps);
						this.setState({ bookingDetail: nextProps.bookingDetail, addons: nextProps.addons, bookingID: nextProps.bookingDetail.id, 
							confirmationNo: nextProps.bookingDetail.resv_id, activities: nextProps.activities });
				}
				else if ( nextProps.error ) {
						console.log(nextProps.error);
						//window.location.href = config.WEBSITE_URL;
						this.setState({ isUnknownBooking: true });
				}
		}

		loopChargeBreakdown(chargeBreakdown) {
				let dataToReturn = [];
				for ( let chargeDate in chargeBreakdown ) {
						dataToReturn.push(
								<tr key={chargeDate}>
										<td>{moment(chargeDate).format('DD MMM YYYY')}</td>
										<td style={{ paddingLeft: '45px' }}>S$ {chargeBreakdown[chargeDate]}</td>
								</tr>
						);
				}

				return dataToReturn;
		}

		loopActivities() {
				let dataToReturn = [];
				if ( this.state.activities && this.state.activities.length > 0 ) {
						for ( let activity in this.state.activities ) {
								dataToReturn.push(
										<div className="activity" key={activity}>
												<a href={ this.state.activities[activity].url }>
														<img className="hide-mobile" src={ this.state.activities[activity].desktop } />
														<img className="show-mobile" src={ this.state.activities[activity].mobile } />
												</a>
										</div>
								);
						}
				}

				return dataToReturn;
		}

		render(){
				let totalNights = 0;
				let checkInDate = '';
				let checkOutDate = '';
				let bbqDates = '';
				let specialRequest = '';
				let roomPreferences = '';
				let chargeBreakdown = '';
				let policy = '';
				if ( this.state.isUnknownBooking ) {
							return (
								<div className="thanks no-banner success-payment" style={{ marginBottom: '20px' }}>
										<div className="thankyou-container pt-0">
												<div style={{fontSize:'18px',fontWeight:'bold', marginBottom: '20px'}}>
		                        Unknown Booking
		                    </div>
												<div className="text-center">
														<button className="btn btn-sm btn-success btn-square book" onClick={() => { window.location.href= config.WEBSITE_URL; }}>Back to Booking Page</button>
												</div>
										</div>
								</div>
							);
				}

				if ( this.state.bookingDetail ) {
						checkInDate = moment(this.state.bookingDetail.check_in_date);
						checkOutDate = moment(this.state.bookingDetail.check_out_date);

						totalNights = checkOutDate.diff(checkInDate, 'day');

						if ( this.state.bookingDetail.bbq_pit_dates !== null && this.state.bookingDetail.bbq_pit_dates !== "" ) {
								if ( this.state.bookingDetail.bbq_pit_dates.indexOf(",") > -1 ) {
										let bbqDatesCount = 1;
										bbqDates = this.state.bookingDetail.bbq_pit_dates.split(',').map(i => {
												return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={bbqDatesCount++}>{i}</p>
										});
								}
								else {
										bbqDates = this.state.bookingDetail.bbq_pit_dates;
								}
						}

						if ( this.state.bookingDetail.special_request != "" ) {
								let specialRequestCount = 1;
								specialRequest = this.state.bookingDetail.special_request.split('\n').map(i => {
										return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
								});
						}

						if ( this.state.bookingDetail.room_preference_codes != "" ) {
								let roomPreferencesCount = 1;
								roomPreferences = this.state.bookingDetail.room_preference_codes.split(',').map(i => {
										return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={roomPreferencesCount++}>{i}</p>
								});
						}

						chargeBreakdown = <table className=""><tbody>{ this.loopChargeBreakdown(this.state.bookingDetail.charge_breakdown) }</tbody></table>;

						let policyCount = 1;
						policy = this.state.bookingDetail.policy.split('\n').map(i => {
								return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={policyCount++}>{i}</p>
						});

						let bookingDetail = this.state.bookingDetail;

						const bookingDetailDataLayer = bookingDetail;
						setTimeout(function() {
								let transactionProducts = new Array();
								let ga4TransactionProducts = new Array();
								transactionProducts.push({
										'sku': bookingDetailDataLayer.room_type_code,
										'name': bookingDetailDataLayer.room_type_name,
										'price': bookingDetailDataLayer.room_charge,
										'quantity': bookingDetailDataLayer.total_rooms
								});

								ga4TransactionProducts.push({
										'item_id': bookingDetailDataLayer.room_type_code,
										'item_name': bookingDetailDataLayer.room_type_name,
										'affiliation': bookingDetailDataLayer.rate_plan_name,
										'price': bookingDetailDataLayer.room_charge.toFixed(2).replace(',', ''),
										'quantity': bookingDetailDataLayer.total_rooms.toString()
								});

								if ( bookingDetail.bbq_pit_package_code !== null ) {
										transactionProducts.push({
											'sku': bookingDetailDataLayer.bbq_pit_package_code,
											'name': 'BBQ Pit',
											'price': bookingDetailDataLayer.bbq_pit_charge,
											'quantity': bookingDetailDataLayer.total_rooms
										});


										ga4TransactionProducts.push({
												'item_id': bookingDetailDataLayer.bbq_pit_package_code,
												'item_name': 'BBQ Pit',
												'affiliation': 'BBQ Pit Add-on',
												'price': bookingDetailDataLayer.bbq_pit_charge.toFixed(2).replace(',', ''),
												'quantity': bookingDetailDataLayer.total_rooms.toString()
										});
								}

								window.dataLayer = window.dataLayer || [];

								window.dataLayer.push({
										'event': 'Message',
										'transactionId': bookingDetailDataLayer.id,
										'transactionAffiliation': bookingDetailDataLayer.rate_plan_name,
										'transactionTotal': bookingDetailDataLayer.grand_total,
										'transactionTax': bookingDetailDataLayer.room_tax,
										'transactionProducts': transactionProducts
								});

								/*window.dataLayer.push({
										'event': 'Message',
										'transaction_id': bookingDetailDataLayer.id,
										'value': bookingDetailDataLayer.grand_total,
										'tax': bookingDetailDataLayer.room_tax,
										'items': ga4TransactionProducts
								});*/



								window.dataLayer.push({
										'event': 'purchase',
										'ecommerce': {
											'transaction_id': bookingDetailDataLayer.id.toString(),
											'affiliation': bookingDetailDataLayer.rate_plan_name,
											'value': bookingDetailDataLayer.grand_total.toFixed(2).replace(',', ''),
											'totalValue': bookingDetailDataLayer.grand_total.toFixed(2).replace(',', ''),
											'tax': bookingDetailDataLayer.room_tax.toFixed(2).replace(',', ''),
											'currency': 'SGD',
											'shipping': '0.00',
											'items': ga4TransactionProducts
										}
								});

								console.log("Run Tag Manager");
						}, 3000);

				}

        return(
					<div className="thanks no-banner success-payment">
							{ !this.state.bookingDetail ? _loading() :

							<div className="thankyou-container pt-0">

									<div style={{fontSize:'14px',fontWeight:'bold', marginBottom: '15px'}}>
										Your booking is confirmed with D'Resort @ Downtown East!
									</div>

									<div style={{ marginBottom: '15px' }}>
										<span style={{ fontWeight: 'bold' }}>Reservation ID: { this.state.bookingID }</span>
										<br />
										<span style={{ fontWeight: 'bold' }}>Confirmation No: { this.state.confirmationNo }</span>
										<br />
										Arrival Date: { checkInDate.format('DD MMM YYYY') } <span className="hide-mobile">|</span><br className="show-mobile" /> Departure Date: { checkOutDate.format('DD MMM YYYY') }
									</div>

									<div style={{ marginBottom: '20px' }}>
										The confirmation letter has been sent to { this.state.bookingDetail.email }.
									</div>

									<h4>Find activities to complement your stay!</h4>

									{ this.loopActivities() }

									{/*<BookingDetail bookingDetail={this.state.bookingDetail} addons={this.state.addons} />

									<div style={{fontSize:'22px'}}>Your Reservation ID: { this.state.bookingID }</div>

									<div style={{margin:'30px auto', fontSize:'14px', fontWeight:'bold'}}>
										<table style={{width:'100%'}} border="0">
											<tbody>
												<tr>
													<th style={{textAlign:'left'}}>Guest Name:</th>
													<th style={{textAlign:'right'}}>{ this.state.bookingDetail.name }</th>
												</tr>
											  <tr>
											    <th style={{textAlign:'left'}}>Reservations:</th>
											    <th style={{textAlign:'right'}}>{ this.state.bookingDetail.total_rooms } Room{ this.state.bookingDetail.total_rooms > 1 ? 's' : null }, { totalNights } Night{ totalNights > 1 ? 's' : null }</th>
											  </tr>
												<tr>
													<th style={{textAlign:'left'}}>Occupancy Per Room:</th>
												 	<th style={{textAlign:'right'}}>
														{ this.state.bookingDetail.total_adults } Adult{this.state.bookingDetail.total_adults > 1 ? 's' : null}
														{ this.state.bookingDetail.total_children > 0 ? ', ' + this.state.bookingDetail.total_children + 'children' : null }
												 	</th>
												</tr>
											  <tr>
											    <th style={{textAlign:'left'}}>Check in:</th>
											    <th style={{textAlign:'right'}}>{ checkInDate.format('DD MMM YYYY') }</th>
											  </tr>
											   <tr>
											    <th style={{textAlign:'left'}}>Check out:</th>
											    <th style={{textAlign:'right'}}>{ checkOutDate.format('DD MMM YYYY') }</th>
											  </tr>
											</tbody>
										</table>
									</div>

									<div style={{margin:'20px auto', textAlign:'left'}}>

										<table style={{width:'100%'}} border="0">
											<tbody>
											 <tr>
											 	<th>

											 	</th>
											 	<th>
											 		<div style={{paddingTop:'10px'}}>
														<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
																{ this.state.bookingDetail.room_type_name }
														</div>
													</div>
											 	</th>
											 </tr>
											</tbody>
										</table>

									</div>



									<div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px 20px 30px', backgroundColor:'#fff', boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>


										<div style={{fontWeight:'bold', fontSize:'16px', paddingLeft:'2px'}}>
												{ this.state.bookingDetail.rate_plan_name }
										</div>


										<table style={{width:'100%', paddingTop:'25px'}} border="0">
											<tbody>
											  <tr>
											 	<th>
											 		<div style={{fontSize:'14px', fontWeight:'normal'}}>
															Room Charges
													</div>
											 	</th>
											 	<th>
													<div style={{fontSize:'14px', textAlign:'right'}}>
															S${ _formatDecimalAmount(this.state.bookingDetail.room_charge) }
													</div>
											 	</th>
											 </tr>
											 <tr>
											 		<td colSpan="2" style={{ fontSize: '14px', paddingLeft: '15px', textAlign:'left', paddingBottom: '15px' }}>
															{chargeBreakdown}
													</td>
											 </tr>
											 { ( this.state.bookingDetail.bbq_pit_dates !== "" && this.state.bookingDetail.bbq_pit_dates !== undefined && this.state.bookingDetail.bbq_pit_dates !== null ) ?
 											<tr>
 												<th>
 													<div style={{fontSize:'14px', fontWeight:'normal'}}>
 															BBQ Pit Charges
 													</div>
 												</th>
 												<th>
 													<div style={{fontSize:'14px', textAlign:'right'}}>
 															S${ _formatDecimalAmount(this.state.bookingDetail.bbq_pit_charge) }
 													</div>
 												</th>
 											</tr> : null }

											{ ( this.state.bookingDetail.bbq_pit_dates !== "" && this.state.bookingDetail.bbq_pit_dates !== undefined && this.state.bookingDetail.bbq_pit_dates !== null ) ?
											 <tr>
												 <td colSpan="2" style={{ fontSize: '14px', paddingLeft: '15px', textAlign:'left', paddingBottom: '15px' }}>
												 		 { bbqDates }
												 </td>
											 </tr> : null }
											</tbody>
										</table>

										<div style={{borderBottom:'3px solid #e1e1e1', paddingTop:'15px'}}></div>


										<table style={{width:'100%',marginTop:'15px',fontWeight:'bold'}} border="0">
											<tbody>
												<tr>
												 	<th style={{width:'30%'}}></th>
												 	<th style={{width:'40%'}}>
												 		<div style={{fontSize:'14px'}}>
															Tax
														</div>
												 	</th>
												 	<th style={{width:'30%'}}>
														<div style={{fontSize:'14px', textAlign:'right'}}>
																S${ _formatDecimalAmount(this.state.bookingDetail.room_tax + this.state.bookingDetail.bbq_pit_tax) }
														</div>
												 	</th>
												</tr>
												<tr>
												 <th style={{width:'30%'}}></th>
												 <th style={{width:'40%'}}>
													 <div style={{fontSize:'14px'}}>
														 Grand Total
													 </div>
												 </th>
												 <th style={{width:'30%'}}>
													 <div style={{fontSize:'14px',textAlign:'right'}}>
														 S${ _formatDecimalAmount(this.state.bookingDetail.grand_total) }
													 </div>
												 </th>
											 </tr>
											</tbody>

										</table>

									</div>

									<div style={{margin:'20px auto', textAlign:'left'}}>
	                    <table border="0" style={{width: '100%'}}>
													<tbody>
			                        <tr>
			                            <th></th>
			                            <th>
																		<div style={{paddingTop:'10px'}}>
																			<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
																					Payment Details
																			</div>
																		</div>
			                            </th>
			                        </tr>
													</tbody>
	                    </table>
	                </div>

									<div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>
										<table style={{width:'100%',marginTop:'10px'}} border="0">
												<tbody>
	                        	<tr>
		                            <th>
		                                <div style={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left'}}>{ this.state.bookingDetail.payment_method }</div>
		                            </th>
		                            <th>
		                                <div style={{fontSize:'14px',textAlign:'right'}}>
																				S${ _formatDecimalAmount(this.state.bookingDetail.grand_total) }
																		</div>
		                            </th>
		                        </tr>
												</tbody>
	                   </table>
	                </div>

									<div style={{margin:'20px auto', textAlign:'left'}}>

										<table style={{width:'100%'}} border="0">
											<tbody>
											 <tr>
											 	<th>

											 	</th>
											 	<th>
											 		<div style={{paddingTop:'10px'}}>
														<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
																Booking Request
														</div>
													</div>
											 	</th>
											 </tr>
											</tbody>
										</table>

									</div>


									<div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>

										<table style={{width:'100%',marginTop:'10px'}} border="0">
											<tbody>
												<tr>
												 	<td style={{ paddingBottom: '15px' }}>
												 		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
															<strong>Room Preferences:</strong>
															<br />
															{roomPreferences != "" ? roomPreferences : '-'}
														</div>
													</td>
												</tr>

												<tr>
												 	<td style={{ paddingBottom: '15px' }}>
												 		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
															<strong>Bed Preferences:</strong>
															<br />
															<p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}}>
															{this.state.bookingDetail.bed_preference_codes != "" ?
																this.state.bookingDetail.bed_preference_codes.replace(/,/g, "<br />") : '-'}
															</p>
														</div>
													</td>
												</tr>

												<tr>
												 	<td style={{ paddingBottom: '15px' }}>
												 		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
															<strong>Estimated Time of Arrival:</strong>
															<br />
															{this.state.bookingDetail.time_of_arrival !== "" ?
																<p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}}>{this.state.bookingDetail.time_of_arrival}</p> : '-'}
														</div>
													</td>
												</tr>

												<tr>
												 	<td style={{ paddingBottom: '15px' }}>
												 		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
															<strong>Special Request:</strong>
															<br />
															{specialRequest ? specialRequest : '-'}
														</div>
													</td>
												</tr>


												<tr>
													<td style={{ fontSize: '14px', fontStyle: 'italic' }}>
															While we try our best to accommodate every request whenever possible, we seek your understanding that special requests are not guaranteed and may incur additional charges.
													</td>
												</tr>
											</tbody>
										</table>

									</div>

									<div style={{margin:'20px auto', textAlign:'left'}}>

										<table style={{width:'100%'}} border="0">
											<tbody>
											 <tr>
											 	<th>

											 	</th>
											 	<th>
											 		<div style={{paddingTop:'10px'}}>
														<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
																Reservation Policies
														</div>
													</div>
											 	</th>
											 </tr>
											</tbody>
										</table>

									</div>

									<div style={{textAlign:'left',marginTop:'10px',marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)' }}>



										<div style={{fontWeight:'bold',fontSize:'16px'}}>
												Room Cancellation Policy
											</div>

										<div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
												{ policy }
										</div>

										<div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
												BBQ Pit Cancellation Policy
											</div>

										<div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
											In the event of cancellation or inclement weather, 100% of BBQ pit booking charges are strictly non-refundable.
										</div>

										<div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
												No-Show/Early Check Out
											</div>

										<div style={{paddingTop:'15px', fontSize:'14px'}}>
											In the event of no-show, early departure or if guests fail to check-in on their stipulated date of arrival, 100% of room charges are strictly non-refundable.
										</div>

									</div>*/}

									{/* <div style={{margin:'20px auto', textAlign:'left'}}>

										<table style={{width:'100%'}} border="0">
											<tbody>
											 <tr>
											 	<th>

											 	</th>
											 	<th>
											 		<div style={{paddingTop:'10px'}}>
														<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
																Need To Know
														</div>
													</div>
											 	</th>
											 </tr>
											</tbody>
										</table>

									</div>

									<div style={{textAlign:'left',marginTop:'10px',marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)' }}>



										<div style={{fontWeight:'bold',fontSize:'16px'}}>
												Check-In
											</div>

										<div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
											Check-in after 3pm
											<br />
											Minimum check-in age: 18 years old
											<br />
											Registered Guests have to show their physical photo identification, credit card used for the booking and NTUC Member card (if applicable) upon check-in. Digital identification cards (Singpass) are not accepted.
										</div>

										<div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
												Check-Out
											</div>

										<div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
											Check-out before 11am
										</div>

										<div style={{fontWeight: 'bold', fontSize: '16px', paddingTop: '15px'}}>Extra Bed & Baby Cot</div>
                    <div style={{paddingTop: '15px', fontSize: '14px'}}>Children 0 - 12 years occupying the same room with at least one adult and using existing bedding will not incur additional charges. Additional charges may apply if extra bed or linen are required.</div>
                    <div style={{paddingTop: '15px', fontSize: '14px'}}>Baby Cot is free.</div>

                    <div style={{fontWeight: 'bold', fontSize: '16px', paddingTop: '15px'}}>Pets</div>
                    <div style={{paddingTop: '15px', fontSize: '14px'}}>Pets are not allowed.</div>

                    <div style={{fontWeight: 'bold', fontSize: '16px', paddingTop: '15px'}}>Ground Floor / Connecting Room </div>
                    <div style={{paddingTop: '15px', fontSize: '14px'}}>Requests for ground floor and/or connecting rooms are subject to availability and surcharge of $30 (excl. GST) per room per night will apply. Confirmation of ground floor rooms will only be available upon arrival. Room numbers will be assigned upon check-in.</div>

										<div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
												Parking
											</div>

										<div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
											Parking charges apply. Parking lot availability is on a first come first served basis.
											<br />
											Parking lots are exclusively for D’Resort guests, multiple entry concession pass can be purchased at iCounter located at level 2 lobby (guests are required to provide vehicle IU number). Concession pass will be charged at S$10 per night for members and S$12 per night for non-members; valid till following day 7.00pm and limited to one concession pass per room. For visiting guests, please visit <a target="_blank" style={{textDecoration: 'underline'}} href="https://www.dresort.com.sg/destination/getting-to-d'resort">https://www.dresort.com.sg/destination/getting-to-d'resort</a> for parking charges.

										</div>

										<div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
												Catering of Food
											</div>

										<div style={{paddingTop:'15px', fontSize:'14px'}}>
											Catering of food is only allowed through our appointed caterer. Please visit <a href="https://www.dresort.com.sg/explore/eat" target="_blank" style={{textDecoration: 'underline' }}>https://www.dresort.com.sg/explore/eat</a> for more details. No other caterers are allowed in the resort premises.
										</div>

									</div>


									<div style={{margin:'20px auto', textAlign:'left'}}>

										<table style={{width:'100%'}} border="0">
											<tbody>
											 <tr>
											 	<th>

											 	</th>
											 	<th>
											 		<div style={{paddingTop:'10px'}}>
														<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
																Important Information
														</div>
													</div>
											 	</th>
											 </tr>
											</tbody>
										</table>

									</div>*/ }

									{ /*<div style={{textAlign:'left',marginTop:'10px',marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)' }}>

										<div style={{paddingTop:'15px', fontSize:'14px'}}>
											<ol>
												<li>In compliance with the Personal Data Protection Commission Singapore (PDPC)’s advisory guidelines on NRIC numbers issued on 31 August 2018, with effect from 1 September 2019, D’Resort will no longer accept check-in on behalf of someone else. Registered guests are required to check-in personally for their stay.</li>
												<li>Guests may not move furnishings or interfere with the electrical network or any other installations in the rooms or on the premises of D’Resort without the consent of the Management. Any damage to the room and the resort's fixtures, fittings and furnishings will be the responsibility of the guests and will be charged accordingly. A minimum $100 cleaning fee will be imposed for excessively messy or dirty rooms.</li>
												<li>Smoking is only permitted at designated areas in D'Resort. All other premises in D'Resort have a non-smoking policy and $200 will be charged to your room for guests in violation of this.</li>
												<li>In the event of false fire alarm activated by guests, a charge of $500 will be imposed.</li>
												<li>Cooking and naked flames in the room is not allowed.</li>
												<li>For safety reasons, the charging of Portable Mobility Devices (PMDs) within D'Resort is not allowed.</li>
												<li>No decorations or fixtures from external vendors are allowed in the room. Please email celebrations@ntucclub.com.sg to purchase authorised decoration materials. Should there be any unauthorised decorations that damage the fittings, charges will apply.</li>
												<li>Maximum guests permitted to stay in the room are based on room type and bedding configuration. For your safety, please abide strictly with the maximum room occupancy due to the Fire & Safety Code as stipulated by the SCDF Fire Code Review Committee and the FSSD Standing Committee. All visitors are to leave the resort premises after 11pm and the management reserves all rights to escort out any additional persons not complying with the Terms & Conditions.</li>
												<li>Please be informed that the Goods and Services Tax (GST) will increase from 7% to 8% from 1 January 2023 onwards. The GST rate at 8% will be imposed on payments and transactions made on or after 1 January 2023.</li>
											</ol>
										</div>

									</div>*/ }

									<div className="text-center">
											<button className="btn btn-sm btn-success btn-square book" onClick={() => { window.location.href= config.MAIN_WEBSITE_URL; }}>Back to Homepage</button>
									</div>

							</div> }


					</div>


        )
    }
}

const mapStateToProps = (state) => {
    let params = { bookingDetail: '', addons: '', activities: '', error: false }

		if ( state.bookingDetail.result && state.bookingDetail.result.bookingDetail ) {
				params.bookingDetail = state.bookingDetail.result.bookingDetail;
				params.addons = state.bookingDetail.result.addons;
				params.activities = state.bookingDetail.result.activities;
    }
		else if ( state.bookingDetail.error || ( state.bookingDetail.result && state.bookingDetail.result.error ) ) {
				params.error = true;
		}

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { getBookingDetail, login } = allAPI;
  return bindActionCreators({ getBookingDetail, login }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(ThankYou));
