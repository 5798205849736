import React from 'react';
import ReactTooltip from 'react-tooltip';
export const isMobileView = () => {
    return window.innerWidth < 769;
}
export const ImgWithTooltip = ({tooltipTitle,imgSrc,keys,containerClassName,containerStyle}) => {
    return (
        <div style={containerStyle} className={containerClassName}>
            <div data-tip data-for={`react-tooltip-${tooltipTitle}-${keys}`}>
                <img style={{width:20}} alt="" src={imgSrc}/>
            </div>
            <ReactTooltip id={`react-tooltip-${tooltipTitle}-${keys}`} className='react-tooltips-class' place='top' type='light' effect='solid'>
                {tooltipTitle}
            </ReactTooltip>
        </div>
    )
}