import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
export default class LightboxComponent extends React.Component{
    static defaultProps = {
        images: [],
        imageCaptions: [],
        closeLigthbox: ()=>console.log('default One')
    }
    constructor(props){
        super(props);
        this.state={
            images: this.props.images,
            imageCaptions: this.props.imageCaptions,
            photoIndex: 0,
        }

    }
    render(){
        const { images, photoIndex, imageCaptions } = this.state;
        return(
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={this.props.closeLigthbox}
                imageCaption={imageCaptions[photoIndex]}
                onMovePrevRequest={() => {
                    this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                }}
                onMoveNextRequest={() => {
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length
                    })
                }}
            />
        )
    }
}
