export const ERROR = 'error';

export const ROOM_RETURN = 'search_rooms_return';
export const RATES_RETURN = 'search_rates_return';
export const ROOM_RATE_RETURN = 'room_rate_return';

export const BOOK_RETURN = 'book_return';
export const RETRIEVE_BOOKING_RETURN = 'retrieve_booking_return';
export const RETRIEVE_BOOKING_WITH_TOKEN_RETURN = 'retrieve_booking_with_token_return';
export const RETRIEVE_BOOKING_FOR_ADDON_RETURN = 'retrieve_booking_for_addon_return';

export const PAYMENT_RETURN = 'payment_return';

export const LOGIN_RETURN = 'login_return';
export const LOGOUT_RETURN = 'logout_return';

export const BOOKING_DETAIL_RETURN = 'booking_detail_return';

export const CONTENT_RETURN = 'content_return';

export const PACKAGE_PRICE_RETURN = 'package_price_return';

export const CANCELATION_BOOKING_RETURN = 'cancellation_booking_return';

export const MODIFICATION_BOOKING_RETURN = 'modification_booking_return';

export const AMEND_BOOKING_ADDON_RETURN = 'amend_booking_addon_return';

export const PREREGISTER_RETURN = 'preregister_return';
