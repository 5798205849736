import * as types from '../const/types'

export default (state = {}, action) => {
    switch (action.type) {
        case types.PAYMENT_RETURN:
            return { ...state,
                result: action.payload,
            }
        case types.ERROR:
            return { ...state,
                error: action.payload.result
            }
        default:
            return state
    }
}
