import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileView, ImgWithTooltip } from '../../misc/helper_functions';
import { _getLocalStorage } from '../../functions/helper_functions';
import { _loading } from '../../functions/message_functions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';
import $ from 'jquery';
var gwdomain = '';
var apigwdomain = '';
class Payment extends React.Component{
	constructor(props){
		super(props);

		let totalPayment = _getLocalStorage(config.KEY_PAYMENT_TOTAL);
		let uniqueID = _getLocalStorage(config.KEY_PAYMENT_UNIQUE_ID);
		let updateToken = _getLocalStorage(config.KEY_UPDATE_TOKEN);
		let isUpdateAddon = _getLocalStorage(config.KEY_AMEND_ADDON);

		this.state={
			txnReq: '',
			hmac: '',
			keyId: '',
			isSearch: false,
			totalPayment: totalPayment ? parseFloat(totalPayment).toFixed(2) : -1,
			uniqueID: uniqueID,
			updateToken: updateToken,
			isUpdateAddon: isUpdateAddon == 'true' ? true : false
		}
	}

	componentDidMount(){
        this.setState({ isSearch: true });
				$("#login").hide();
				window.scrollTo(0, 0);

		const script_env = document.createElement("script");
		script_env.src = config.ENETS_API_URL + "GW2/pluginpages/env.jsp";
		script_env.async = true;
		script_env.onload = () => this.envScriptLoaded();
		document.body.appendChild(script_env);
	}

	componentWillReceiveProps(nextProps) {
        if ( this.state.isSearch && nextProps.payment ){
						window.sendPayLoad(nextProps.payment.txnReq, nextProps.payment.hmac, nextProps.payment.keyId);
						this.setState({ isSearch: false });
        }
	}

	envScriptLoaded(){
		gwdomain = window.gwdomain;
		apigwdomain = window.apigwdomain;

		const script_jquery = document.createElement("script");
		script_jquery.src = config.ENETS_API_URL + "GW2/js/jquery-3.1.1.min.js";
		script_jquery.async = true;
		script_jquery.onload = () => this.jqueryScriptLoaded();
		document.body.appendChild(script_jquery);
	}

	/* jqueryScriptLoaded(){
		const script_sha = document.createElement("script");
		script_sha.src = config.ENETS_API_URL + "demo/assets/js/sha256.js";
		script_sha.async = true;
		script_sha.onload = () => this.shaScriptLoaded();
		document.body.appendChild(script_sha);
	} */

	jqueryScriptLoaded(){
		const script_app = document.createElement("script");
		script_app.src = config.ENETS_API_URL + "GW2/js/apps.js";
		script_app.async = true;
		script_app.onload = () => this.appScriptLoaded();
		console.log(script_app);
		document.body.appendChild(script_app);
	}

	appScriptLoaded(){
		$("body").on('DOMSubtreeModified', "#ajaxResponse", function() {
			var old_html = $('#ajaxResponse').html();
			console.log(old_html);
			if (old_html.indexOf('bootstrap') != -1){
				$('body').addClass('payment-page-body');
			}
		});

			if ( this.state.isUpdateAddon && this.state.updateToken ) {
					this.props.paymentAddonDetails(this.state.totalPayment, this.state.uniqueID, this.state.updateToken);
			}
			else {
        this.props.paymentDetails(this.state.totalPayment, this.state.uniqueID);
			}
	}

    render(){
        return(
			<div>
				{this.state.isSearch ? _loading() : ""}
				<div className="payment-page">
					<div className="container">
						<div id="ajaxResponse"><div className="text-center">Please wait...</div></div>
					</div>
				</div>
			</div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { payment: [] }
    if ( state.payment.result ) {
        params.payment = state.payment.result;
    }

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { paymentDetails, paymentAddonDetails } = allAPI;
  return bindActionCreators({ paymentDetails, paymentAddonDetails }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Payment));
