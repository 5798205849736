export default {
    img:{
        logo:require('./img/dresort-logo.png'),
        bannerTwo:require('./img/stay-banner2.png'),
        standardBBQ:require('./img/standard_BBQ.jpg'),
        standardWWW:require('./img/standard_WWW.jpg'),
        loading:require('./img/loading.gif')
    },
    icon:{
        copyRight:require('./img/icon/bkg-icon-copyright.png'),
        clubLogo:require('./img/icon/NTUCClublogo.png'),
        fbIcon:require('./img/icon/fb.png'),
        info:require('./img/icon/information_icon.png')
    }
}