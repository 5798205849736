import React from 'react';
import $ from 'jquery';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

class Main extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0);
        $("#login").hide();

        // Remove all local storage details except search details
        localStorage.removeItem(config.KEY_ROOM_NAME);
        localStorage.removeItem(config.KEY_ROOM_CODE);
        localStorage.removeItem(config.KEY_RATE_CODE);
        // localStorage.removeItem(config.KEY_CHECK_IN_DATE);
        // localStorage.removeItem(config.KEY_CHECK_OUT_DATE);
        // localStorage.removeItem(config.KEY_TOTAL_NIGHT);
        // localStorage.removeItem(config.KEY_TOTAL_ROOMS);
        // localStorage.removeItem(config.KEY_TOTAL_ADULT);
        // localStorage.removeItem(config.KEY_TOTAL_CHILDREN);
        // localStorage.removeItem(config.KEY_SPECIAL_CODE_TYPE);
        // localStorage.removeItem(config.KEY_SPECIAL_CODE);
        localStorage.removeItem(config.KEY_SPECIAL_REQUESTS);

        localStorage.removeItem(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES);
        localStorage.removeItem(config.KEY_TOTAL_TAXES_AND_FEES);
        localStorage.removeItem(config.KEY_TAX_INCLUSIVE);
        localStorage.removeItem(config.KEY_GRAND_TOTAL);

        localStorage.removeItem(config.KEY_ADDONS);
        localStorage.removeItem(config.KEY_TOTAL_PACKAGES_AMOUNT);
        localStorage.removeItem(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
        localStorage.removeItem(config.KEY_SELECTED_ADDON);

        localStorage.removeItem(config.KEY_PAYMENT_TOTAL);
        localStorage.removeItem(config.KEY_PAYMENT_UNIQUE_ID);

        // Remove if there is any booking modification's data
        localStorage.removeItem(config.KEY_UPDATE_TOKEN);
        localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);
        localStorage.removeItem(config.KEY_IS_MEMBER_PREVIOUS_BOOKING);

        localStorage.removeItem(config.KEY_AUTHORISED_DOMAIN);

        let cardNo = localStorage.getItem(config.KEY_CARD_NO);
        if ( cardNo !== null ) {
            this.props.login(cardNo);
        }
    }

    render(){
        return(
            <div className="thanks no-banner cancel-payment">
                <div className="thankyou-container">
                    <div style={{fontSize:'14px',fontWeight:'bold'}}>
                        Payment is unsuccessful due to the following
                    </div>
                    <div style={{fontSize: '12px',marginTop:'10px', textAlign: 'left', fontWeight: 'normal'}}>
                        { this.props.match.params.message ? decodeURIComponent((this.props.match.params.message + '').replace(/\+/g, '%20')) : '' }
                    </div>
                    <div style={{marginTop:15}} className="text-center">
                        <button class="btn btn-sm btn-success btn-square book" onClick={() => { this.props.history.push('/') }}>Back to Booking Page</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { loginReturn: null }

    if ( state.login.result ) {
        params.loginReturn = state.login.result;
    }

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { login } = allAPI;
  return bindActionCreators({ login }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Main));
