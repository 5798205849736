import React from 'react';
import { Route, withRouter } from 'react-router-dom';
//Plugin Style
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
//Font Awesome
import { faSetUp } from '../misc/fontAwesome';
//Pages
import Home from './Home';
import Book from './Book';
import AddOns from './AddOns';
import AmendAddons from './AddOns/amend.js';
import ByRates from './ByRates';
import Payment from './Payment';
import ThankYou from './ThankYou';
import Cancel from './Cancel';
import ManageBooking from './ManageBooking';
//Component
import HeaderComponent from '../component/Header';
import FooterComponent from '../component/Footer';
//Set Up Font Awesome
faSetUp();
class Main extends React.Component{
    render(){
        return(
            <div>
                <HeaderComponent/>
                <Route exact path='/' component={withRouter(Home)}/>
                <Route exact path='/book' component={withRouter(Book)}/>
                <Route exact path='/addons' component={withRouter(AddOns)}/>
                <Route exact path='/addons/amend' component={withRouter(AmendAddons)}/>
                <Route exact path='/byrates' component={withRouter(ByRates)}/>
                <Route exact path='/payment' component={withRouter(Payment)}/>
                <Route exact path='/thankyou/:id?' component={withRouter(ThankYou)}/>
                <Route exact path='/cancel/:message?' component={withRouter(Cancel)}/>
                <Route exact path='/managebooking' component={withRouter(ManageBooking)}/>
                <FooterComponent/>
            </div>
        )
    }
}
export default Main;
