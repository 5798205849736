import axios from 'axios';
import * as types from '../const/types';
import * as config from '../const/config';
import * as apis from '../const/apis';
import { _createFormData, _getLocalStorage, linkccSHA512 } from '../functions/helper_functions';

const ROOT_URL = `${config.API_URL}`;

export const searchRooms = (checkInDate, checkOutDate, totalRooms, totalAdults, totalChildrens, specialCodeType, specialCode, isNewGST) => {
  	return dispatch => {
 		//dispatch({ type: types.GRADE_STATUS_ERROR, payload: '' });
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

      //console.log(`${ROOT_URL}/${apis.API_SEARCH_ROOM}`);

    	axios
      		.post(`${ROOT_URL}/${apis.API_SEARCH_ROOM}`, _createFormData({
    			startDate: checkInDate,
    			endDate: checkOutDate,
    			numberOfRooms: totalRooms,
    			adult: totalAdults,//_getLocalStorage("token"),
    			children: totalChildrens,
          specialCodeType: specialCodeType,
          specialCode: specialCode,
          dresort: dresort,
          isNewGST: isNewGST
    	}))
      	.then(response => {
            dispatch({ type: types.ROOM_RETURN, payload: response.data });

      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Search Rooms");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const searchRates = (checkInDate, checkOutDate, totalRooms, totalAdults, totalChildrens, specialCodeType, specialCode) => {
  	return dispatch => {
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    	axios
      		.post(`${ROOT_URL}/${apis.API_SEARCH_RATES}`, _createFormData({
    			startDate: checkInDate,
    			endDate: checkOutDate,
    			numberOfRooms: totalRooms,
    			adult: totalAdults,
    			children: totalChildrens,
          specialCodeType: specialCodeType,
          specialCode: specialCode,
          dresort
    	}))
      	.then(response => {
            dispatch({ type: types.RATES_RETURN, payload: response.data });

      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Search Rates");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const roomRateDetails = (checkInDate, checkOutDate, totalRooms, totalAdults, totalChildrens, ratePlanCode, roomTypeCode, specialCodeType, specialCode) => {
  	return dispatch => {
 		//dispatch({ type: types.GRADE_STATUS_ERROR, payload: '' });
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    	axios
      		.post(`${ROOT_URL}/${apis.API_ROOM_RATE_DETAIL}`, _createFormData({
    			startDate: checkInDate,
    			endDate: checkOutDate,
    			numberOfRooms: totalRooms,
    			adult: totalAdults,//_getLocalStorage("token"),
    			children: totalChildrens,
          ratePlan: ratePlanCode,
          roomType: roomTypeCode,
          specialCodeType,
          specialCode,
          dresort
    	}))
      	.then(response => {
            //console.log(response);
            dispatch({ type: types.ROOM_RATE_RETURN, payload: response.data });

      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Room Rate Details");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const getAddonPrice = (checkInDate, checkOutDate, totalRooms, totalAdults, totalChildrens, addonID) => {
  	return dispatch => {
 		  //dispatch({ type: types.GRADE_STATUS_ERROR, payload: '' });
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    	axios
      		.post(`${ROOT_URL}/${apis.API_GET_ADDON_PRICE}`, _createFormData({
    			startDate: checkInDate,
    			endDate: checkOutDate,
    			numberOfRooms: totalRooms,
    			adult: totalAdults,//_getLocalStorage("token"),
    			children: totalChildrens,
          addonID: addonID,
          dresort
    	}))
      	.then(response => {
            //console.log(response);
            dispatch({ type: types.PACKAGE_PRICE_RETURN, payload: response.data });

      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Get Package Price");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const book = (checkInDate, checkOutDate, totalRooms, totalAdults, totalChildrens, ratePlanCode, roomTypeCode, grandTotal,
        name, guestEmail, guestIDD, guestMobile, guestName, timeOfArrival, roomPreferences, bedPreferences, comments, selectedAddons,
        specialCodeType, specialCode, mailConsentEmail, mailConsentText, mailConsentCall, modifyBookingID, updateToken) => {
  	return dispatch => {
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

 			axios
      		.post(`${ROOT_URL}/${apis.API_BOOK_ROOM}`, _createFormData({
    			startDate: checkInDate,
    			endDate: checkOutDate,
    			numberOfRooms: totalRooms,
    			adult: totalAdults,//_getLocalStorage("token"),
    			children: totalChildrens,
          ratePlan: ratePlanCode,
          roomType: roomTypeCode,
          grandTotal: grandTotal,
          name: name,
          guestEmail: guestEmail,
          guestIDD: guestIDD,
          guestMobile: guestMobile,
          guestName: guestName,
          timeOfArrival: timeOfArrival,
          roomPreferences: roomPreferences,
          bedPreferences: bedPreferences,
          comments: comments,
          selectedAddons,
          specialCodeType,
          specialCode,
          mailConsentEmail,
          mailConsentText,
          mailConsentCall,
          dresort,
          modifyBookingID,
          updateToken
    	}))
      	.then(response => {
            //console.log(response);
            dispatch({ type: types.BOOK_RETURN, payload: response.data });

      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Book Room");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const paymentDetails = (totalPayment, uniqueID) => {
  	return dispatch => {
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    	axios
      	.post(`${ROOT_URL}/${apis.API_PAYMENT}`, _createFormData({
            totalPayment: totalPayment,
            uniqueID: uniqueID,
            mode: 'pre_live',
            dresort
        }))
      	.then(response => {
            dispatch({ type: types.PAYMENT_RETURN, payload: response.data });
      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Payment");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const paymentAddonDetails = (totalPayment, uniqueID, updateToken) => {
  	return dispatch => {
      let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    	axios
      	.post(`${ROOT_URL}/${apis.API_PAYMENT}`, _createFormData({
            totalPayment: totalPayment,
            uniqueID: uniqueID,
            mode: 'pre_live',
            update_token: updateToken,
            'isAmendAddon': true
        }))
      	.then(response => {
            dispatch({ type: types.PAYMENT_RETURN, payload: response.data });
      	})
      	.catch((error) => {
        	if ( config.IS_DEVELOPMENT ) {
	          	console.log("Payment");
	          	console.log(error);
	        }
        	dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    	});
  	}
}

export const login = (cardNo) => {
  return dispatch => {
    let refNo = cardNo;
    if ( cardNo.charAt(0) === '4' ) {
        cardNo = linkccSHA512(cardNo);
    }

    localStorage.setItem(config.KEY_CARD_NO, cardNo);

    let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    axios
      .post(`${ROOT_URL}/${apis.API_LOGIN}`, _createFormData({
          cardNo: cardNo,
          refNo,
          dresort
      }))
      .then(response => {
          //console.log(response.data);
          dispatch({ type: types.LOGIN_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Login");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const logout = () => {
  return dispatch => {
    let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    axios
      .post(`${ROOT_URL}/${apis.API_LOGOUT}`, _createFormData({
          dresort
      }))
      .then(response => {
          //console.log(response.data);
          dispatch({ type: types.LOGOUT_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Logout");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const getBookingDetail = (bookingID) => {
  return dispatch => {
    let dresort = _getLocalStorage(config.KEY_ACCESS_CODE);

    axios
      .post(`${ROOT_URL}/${apis.API_BOOKING_DETAIL}`, _createFormData({
          bookingID: bookingID,
          getActivity: true,
          dresort
      }))
      .then(response => {
          dispatch({ type: types.BOOKING_DETAIL_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Booking Detail");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const retrieveBooking = (bookingID, guestName, guestEmail) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_RETRIEVE_BOOKING}`, _createFormData({
          bookingID,
          guestName,
          guestEmail
      }))
      .then(response => {
          dispatch({ type: types.RETRIEVE_BOOKING_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Retrieve Booking");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const retrieveBookingWithToken = (bookingID, updateToken) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_RETRIEVE_BOOKING_WITH_TOKEN}`, _createFormData({
          bookingID,
          updateToken
      }))
      .then(response => {
          dispatch({ type: types.RETRIEVE_BOOKING_WITH_TOKEN_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Retrieve Booking With Token");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const retrieveBookingForAddon = (bookingID, updateToken) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_RETRIEVE_BOOKING_FOR_ADDON}`, _createFormData({
          bookingID,
          updateToken
      }))
      .then(response => {
          dispatch({ type: types.RETRIEVE_BOOKING_FOR_ADDON_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Retrieve Booking For Addon");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const amendBookingAddons = (bookingID, updateToken, selectedAddons) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_AMEND_BOOKING_ADDON}`, _createFormData({
          bookingID,
          updateToken,
          selectedAddons,
          'isAmendAddon': true
      }))
      .then(response => {
          dispatch({ type: types.AMEND_BOOKING_ADDON_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("AmendBookingAddon");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const cancelBooking = (bookingID, cancellationType, cancellationReason, cancellationToken) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_CANCEL_BOOKING}`, _createFormData({
          bookingID,
          cancellationType,
          cancellationReason,
          cancellationToken
      }))
      .then(response => {
          dispatch({ type: types.CANCELATION_BOOKING_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Cancel Booking");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const modifyBooking = (bookingID, guestName, guestIDD, guestMobile, isGuestDetailsModified,
    roomPreferences, bedPreference, timeOfArrival, specialRequest, isBookingRequestModified,
    isBookingDetailsModified, updateToken) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_MODIFY_BOOKING}`, _createFormData({
          bookingID,
          guestName,
          guestIDD,
          guestMobile,
          isGuestDetailsModified,
          roomPreferences,
          bedPreference,
          timeOfArrival,
          specialRequest,
          isBookingRequestModified,
          isBookingDetailsModified,
          updateToken
      }))
      .then(response => {
          dispatch({ type: types.MODIFICATION_BOOKING_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Modify Booking");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const preregister = (bookingID, guestsDetails, eta, purpose, updateToken) => {
  return dispatch => {
    axios
      .post(`${ROOT_URL}/${apis.API_PREREGISTER}`, _createFormData({
          bookingID,
          guestsDetails,
          eta,
          purpose,
          updateToken
      }))
      .then(response => {
          dispatch({ type: types.PREREGISTER_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Preregister");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}

export const getContent = () => {
  return dispatch => {
    axios
      .get(`${ROOT_URL}/${apis.API_GET_CONTENT}`)
      .then(response => {
          dispatch({ type: types.CONTENT_RETURN, payload: response.data });
      })
      .catch((error) => {
        if ( config.IS_DEVELOPMENT ) {
            console.log("Get Content");
            console.log(error);
        }
        dispatch({ type: types.ERROR, payload: "Connection failed, please try again." });
    });
  }
}
