import React from 'react';
import { _formatDecimalAmount } from '../functions/helper_functions';
import moment from 'moment';

import BookingDetailRoom from './BookingDetailRoom';

export default class BookingDetail extends React.Component{
    static defaultProps = {
        bookingDetail: null
    }
    constructor(props){
        super(props);
        this.state={
            bookingDetail: this.props.bookingDetail,
            addons: this.props.addons,
            isStatusCancelled: this.props.isStatusCancelled,
            isLongTermCode: this.props.bookingDetail.special_code_type == 'Long Term Code',
            isModify: this.props.isModify
        }
    }

    loopChargeBreakdown(chargeBreakdown) {
        let dataToReturn = [];
				for ( let chargeDate in chargeBreakdown ) {
						dataToReturn.push(
								<tr key={chargeDate}>
										<td>{moment(chargeDate).format('DD MMM YYYY')}</td>
										<td style={{ paddingLeft: '45px' }}>S$ { this.state.isLongTermCode ? '0.00' : chargeBreakdown[chargeDate] }</td>
								</tr>
						);
				}

				return dataToReturn;
		}

    renderAddons() {
        let addons = [];
        if ( this.state.addons.length > 0 ) {
            addons.push(
                <tr key="addon">
                    <td colSpan="2"><strong>Addon(s):</strong></td>
                </tr>
            )

            for ( let index in this.state.addons ) {
                let addon = this.state.addons[index];

                addons.push(
                    <tr key={index}>
                      <th>
                        <div style={{fontSize:'14px', fontWeight:'normal'}}>
                            { addon.name } ({ moment(addon.from).format('DD MMM YYYY') }{ addon.from != addon.to ? " - " + moment(addon.to).format('DD MMM YYYY') : null }) x { addon.qty }
                        </div>
                      </th>
                      <th>
                        <div style={{fontSize:'14px', textAlign:'right'}}>
                            S${ _formatDecimalAmount(addon.amount) }
                        </div>
                      </th>
                    </tr>
                );
            }
        }

        return addons;
    }

    render(){
        let totalNights = 0;
        let checkInDate = '';
        let checkOutDate = '';
        let bbqDates = '';
        let specialRequest = '';
        let roomPreferences = '';
        let chargeBreakdown = '';
        let policy = '';

        checkInDate = moment(this.state.bookingDetail.check_in_date);
        checkOutDate = moment(this.state.bookingDetail.check_out_date);

        totalNights = checkOutDate.diff(checkInDate, 'day');

        if ( this.state.bookingDetail.bbq_pit_dates !== null && this.state.bookingDetail.bbq_pit_dates !== "" ) {
            if ( this.state.bookingDetail.bbq_pit_dates.indexOf(",") > -1 ) {
                let bbqDatesCount = 1;
                bbqDates = this.state.bookingDetail.bbq_pit_dates.split(',').map(i => {
                    return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={bbqDatesCount++}>{i}</p>
                });
            }
            else {
                bbqDates = this.state.bookingDetail.bbq_pit_dates;
            }
        }

        if ( this.state.bookingDetail.special_request && this.state.bookingDetail.special_request != "" ) {
            let specialRequestCount = 1;

            if ( Array.isArray(this.state.bookingDetail.special_request) ) {
                specialRequest = this.state.bookingDetail.special_request.map(i => {
                    return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
                });
            }
            else {
                specialRequest = this.state.bookingDetail.special_request.split('\n').map(i => {
                    return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
                });
            }
        }

        if ( this.state.bookingDetail.room_preference_codes != "" ) {
            let roomPreferencesCount = 1;
            roomPreferences = this.state.bookingDetail.room_preference_codes.split(',').map(i => {
                return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={roomPreferencesCount++}>{i}</p>
            });
        }

        chargeBreakdown = <table className=""><tbody>{ this.loopChargeBreakdown(this.state.bookingDetail.charge_breakdown) }</tbody></table>;

        let policyCount = 1;
        policy = this.state.bookingDetail.policy.split('\n').map(i => {
            return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={policyCount++}>{i}</p>
        });

        let inclusivePackagesDisplay = null;
        if ( this.state.bookingDetail.rate_plan_packages != "" && this.state.bookingDetail.rate_plan_packages !== '[]' && this.state.bookingDetail.rate_plan_packages.length > 0 ) {
            inclusivePackagesDisplay = this.state.bookingDetail.rate_plan_packages.map(i => {
                return <p key={ i.name } style={{ textAlign: 'left', marginBottom: '0', paddingLeft: '15px' }}>{i.name}</p>
            });
        }

        let hasPreviousBooking = this.state.bookingDetail.previous_booking_id !== undefined && this.state.bookingDetail.previous_booking_id !== null && this.state.bookingDetail.previous_booking_id !== 'null';
        let isNoPayment = this.state.bookingDetail.payment_method == "";

        return(
            <div className="booking-detail">
                <div style={{fontSize:'22px'}}>Your Reservation ID: { this.state.bookingDetail.id }</div>

                <div style={{margin:'30px auto', fontSize:'14px', fontWeight:'bold'}}>
                  <table style={{width:'100%'}} border="0">
                    <tbody>
                      <tr>
                        <th style={{textAlign:'left'}}>Guest Name:</th>
                        <th style={{textAlign:'right'}}>{ this.state.bookingDetail.name }</th>
                      </tr>
                      <tr>
                        <th style={{textAlign:'left'}}>Reservations:</th>
                        <th style={{textAlign:'right'}}>{ this.state.bookingDetail.total_rooms } Room{ this.state.bookingDetail.total_rooms > 1 ? 's' : null }, { totalNights } Night{ totalNights > 1 ? 's' : null }</th>
                      </tr>
                      <tr>
                        <th style={{textAlign:'left'}}>Occupancy Per Room:</th>
                        <th style={{textAlign:'right'}}>
                          { this.state.bookingDetail.total_adults } Adult{this.state.bookingDetail.total_adults > 1 ? 's' : null}
                          { this.state.bookingDetail.total_children > 0 ? ', ' + this.state.bookingDetail.total_children + ' children' : null }
                        </th>
                      </tr>
                      <tr>
                        <th style={{textAlign:'left'}}>Check in:</th>
                        <th style={{textAlign:'right'}}>{ checkInDate.format('DD MMM YYYY') }</th>
                      </tr>
                       <tr>
                        <th style={{textAlign:'left'}}>Check out:</th>
                        <th style={{textAlign:'right'}}>{ checkOutDate.format('DD MMM YYYY') }</th>
                      </tr>
                       {/*<tr>
                        <th style={{textAlign:'left'}}>Reservation ID:</th>
                        <th style={{textAlign:'right'}}>{ this.state.bookingID }</th>
                      </tr>*/}
                    </tbody>
                  </table>
                </div>

                { this.state.isStatusCancelled ? null :

                <div>
                    <BookingDetailRoom bookingDetail={this.state.bookingDetail} addons={this.state.addons} isModify={this.state.isModify} />

                    { ( !isNoPayment && ( !hasPreviousBooking || ( hasPreviousBooking && this.state.bookingDetail.modify_difference_amount > 0 ) ) ) ?
                    <div style={{margin:'20px auto', textAlign:'left'}}>
                        <table border="0" style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>
                                      <div style={{paddingTop:'10px'}}>
                                        <div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
                                            Payment Details
                                        </div>
                                      </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div> : null }

                    { ( !isNoPayment && ( !hasPreviousBooking || ( hasPreviousBooking && this.state.bookingDetail.modify_difference_amount > 0 ) ) ) ?
                    <div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>
                      <table style={{width:'100%',marginTop:'10px'}} border="0">
                          <tbody>
                              <tr>
                                  <th>
                                      <div style={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left'}}>{ this.state.bookingDetail.payment_method }</div>
                                  </th>
                                  <th>
                                  { hasPreviousBooking ?
                                    <div style={{fontSize:'14px',textAlign:'right'}}>
                                        S${ _formatDecimalAmount(this.state.bookingDetail.modify_difference_amount) }
                                    </div>
                                    :
                                    <div style={{fontSize:'14px',textAlign:'right'}}>
                                        S${ _formatDecimalAmount(this.state.bookingDetail.grand_total) }
                                    </div>
                                  }

                                  </th>
                              </tr>
                          </tbody>
                       </table>
                    </div> : null }

                    <div style={{margin:'20px auto', textAlign:'left'}}>

                      <table style={{width:'100%'}} border="0">
                        <tbody>
                         <tr>
                          <th>

                          </th>
                          <th>
                            <div style={{paddingTop:'10px'}}>
                              <div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
                                  Booking Request
                              </div>
                            </div>
                          </th>
                         </tr>
                        </tbody>
                      </table>

                    </div>


                    <div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>

                      <table style={{width:'100%',marginTop:'10px'}} border="0">
                        <tbody>
                          <tr>
                            <td style={{ paddingBottom: '15px' }}>
                              <div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
                                <strong>Room Preferences:</strong>
                                <br />
                                {roomPreferences != "" ? roomPreferences : '-'}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ paddingBottom: '15px' }}>
                              <div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
                                <strong>Bed Preferences:</strong>
                                <br />
                                <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}}>
                                { ( this.state.bookingDetail.bed_preference_codes && this.state.bookingDetail.bed_preference_codes != "" ) ?
                                  this.state.bookingDetail.bed_preference_codes.replace(/,/g, "<br />") : '-'}
                                </p>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ paddingBottom: '15px' }}>
                              <div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
                                <strong>Estimated Time of Arrival:</strong>
                                <br />
                                {this.state.bookingDetail.time_of_arrival !== "" ?
                                  <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}}>{this.state.bookingDetail.time_of_arrival}</p> : '-'}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ paddingBottom: '15px' }}>
                              <div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
                                <strong>Special Request:</strong>
                                <br />
                                {specialRequest ? specialRequest : '-'}
                              </div>
                            </td>
                          </tr>


                          <tr>
                            <td style={{ fontSize: '14px', fontStyle: 'italic' }}>
                                While we try our best to accommodate every request whenever possible, we seek your understanding that special requests are not guaranteed and may incur additional charges.
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>

                    <div style={{margin:'20px auto', textAlign:'left'}}>

                      <table style={{width:'100%'}} border="0">
                        <tbody>
                         <tr>
                          <th>

                          </th>
                          <th>
                            <div style={{paddingTop:'10px'}}>
                              <div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
                                  Reservation Policies
                              </div>
                            </div>
                          </th>
                         </tr>
                        </tbody>
                      </table>

                    </div>

                    <div style={{textAlign:'left',marginTop:'10px',marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)' }}>



                      <div style={{fontWeight:'bold',fontSize:'16px'}}>
                          Room Cancellation Policy
                      </div>

                      <div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
                          { policy }
                      </div>

                      <div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
                          BBQ Pit Cancellation Policy
                        </div>

                      <div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
                        In the event of cancellation or inclement weather, 100% of BBQ pit booking charges are strictly non-refundable.
                      </div>

                      <div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
                          No-Show/Early Check Out
                        </div>

                      <div style={{paddingTop:'15px', fontSize:'14px'}}>
                        In the event of no-show, early departure or if guests fail to check-in on their stipulated date of arrival, 100% of room charges are strictly non-refundable.
                      </div>

                    </div>
                </div> }
            </div>
        )
    }
}
