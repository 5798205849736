import React from 'react';
import ReactDOM from 'react-dom';
import MyAssets from '../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import BackgroundImage from '../assets/img/stay-banner2.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { isMobileView } from '../misc/helper_functions';
import { _getLocalStorage } from '../functions/helper_functions';
import { _loading } from '../functions/message_functions';
import $ from 'jquery';
import moment from 'moment';
import DOMPurify from 'dompurify';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as allAPI from '../apis/all';
import * as config from '../const/config';

import MemberCardImage from '../assets/img/ntuc-link-desktop-image.jpg';
import MemberCardMobileImage from '../assets/img/ntuc-link-mobile-image.jpg';

var lastHeight = 0;
var lastOffSet = 0;
const COUNT_PERSON = {
    PLUS:"PLUS",
    MIN:"MIN",
    ADULT:"ADULT",
    CHILDREN:"CHILDREN"
}
const DATE_NOW_STRING = moment().format('MM/DD/YYYY');

class Main extends React.Component{
    constructor(props){
        super(props);
    		var current_page = window.location.pathname;
    		var hide_middle_bar = false;
    		if (current_page.indexOf('thankyou') != -1 || current_page.indexOf('payment') != -1){
    	     hide_middle_bar = true;
    		}

        let isMember = _getLocalStorage(config.KEY_IS_MEMBER);
        let accessToken = _getLocalStorage(config.KEY_ACCESS_CODE);
        this.state={
            totalRoom:2,
            totalNight:0,
            startDateString:'',
            endDateString:'',
            totalAdult:0,
            totalChildren:0,
            hideMiddleBar:hide_middle_bar,
            cardNumber: '',
            loginError: '',
            isSubmitLogin: false,
            isSubmitLogout: false,
            isLoggedIn: ( isMember !== null && accessToken !== null ) ? isMember : false,
            currentScreenWidth: window.innerWidth,
            currentScreenHeight: window.innerHeight
        }
    }
    componentDidMount(){
        document.addEventListener('scroll',this.handleScroll);
        window.addEventListener('click',this.handleClick);
        this.forceUpdate();
        this.showHideCountPerson(false);
        this.showHideSpesialCode(false);

        window.addEventListener("resize", this.resize.bind(this));
    }
    resize() {
        if ( this.state.currentScreenWidth > 760 && this.state.currentScreenWidth !== window.innerWidth ) { //|| this.state.currentScreenHeight !== window.innerHeight ) {
            window.location.reload();
        }
    }
    componentWillUnmount(){
        document.removeEventListener('scroll',this.handleScroll);
        window.removeEventListener('click',this.handleClick);
        window.removeEventListener('resize', this.resize);
    }
    handleLogin(event) {
        this.props.login(this.state.cardNumber);
        this.setState({ isSubmitLogin: true });
    }
    handleCardNumber(event) {
        this.setState({ cardNumber: event.target.value, loginError: '' });
    }
    handleClick = (event) => {
        if(!event.target.className || !event.target.className.includes || !event.target.className.includes('count-person-pop-up')){
            this.showHideCountPerson(false);
        }
        if(!event.target.className || !event.target.className.includes || !event.target.className.includes('spesial-code-pop-up')){
            this.showHideSpesialCode(false);
        }
        if(!event.target.className || !event.target.className.includes || !event.target.className.includes('dropdown-toggle')){
            $('.dropdown-menu').removeClass('show');
        }
    }
    handleScroll = (event) => {
        let target = 'total-reservation';
		if (document.getElementById(target)){
			let { offsetHeight, offsetTop } = document.getElementById(target);
			if(!lastHeight && !lastOffSet){
				lastHeight = offsetHeight;
				lastOffSet = offsetTop;
			}
			let widthTarget = $(`#${target}`).outerWidth();
			if(window.pageYOffset < (lastHeight + lastOffSet)){
				$(`#${target}`).css({
					position:'relative',
					width:'',
				})
			}else if(window.pageYOffset > (lastHeight + lastOffSet)){
				$(`#${target}`).css({
					position:'fixed',
					top:0,
					width:widthTarget
				})
			}
		}
    }
    openMenu = () => {
        $('body').css({
            position: 'absolute'
        }).removeClass('body-left').addClass('body-right');
        $('.topbar-menu').removeClass('menu-left').addClass('menu-right');
    }
    closeMenu = () => {
        $('body').removeClass('body-right').addClass('body-left');
        $('.topbar-menu').removeClass('menu-right').addClass('menu-left')
    }
    countPersonStyle = () => {
        let person = $('#person-input-container');
        // let parent = $('.banner-slider').height();
        if(person.length){
            return {
                left: isMobileView()?(-person.offset().left + 20):'0px',
                width:'350px'
            }
        }
    }
    showHideCountPerson = (status) => {
        status ? $('#count_person').show() : $('#count_person').hide();
    }
    showHideSpesialCode = (status) => {
        status ? $('#popup-specialcode').show() : $('#popup-specialcode').hide();
    }
    NewRoom = ({currentNumber}) => {
        return(
            <div className="row input-spin count-person-pop-up">
                <div className="col-2 col-lg-2 count-person-pop-up">
                    <span className="room count-person-pop-up">Room {currentNumber}</span>
                </div>
                <div className="col-5 col-lg-5 count-person-pop-up">
                    <div className="input-group input-group-sm count-person-pop-up">
                        <div className="input-group-prepend count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                        </div>
                        <input onChange={()=>{}} defaultValue='' id={`input-adults-${currentNumber}`} placeholder='0' className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                        <div className="input-group-append count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                        </div>
                    </div>
                </div>
                <div className="col-5 col-lg-5 count-person-pop-up">
                    <div className="input-group input-group-sm count-person-pop-up">
                        <div className="input-group-prepend count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                        </div>
                        <input onChange={()=>{}} id={`input-children-${currentNumber}`} defaultValue='' placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                        <div className="input-group-append count-person-pop-up">
                            <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    addNewRoom = () => {
        const currentNumber = this.state.totalRoom;
        let parent = document.getElementById('row-room');
        let newDiv = document.createElement('div');
        ReactDOM.render(<this.NewRoom currentNumber={currentNumber}/>, newDiv);
        parent.appendChild(newDiv);
        this.setState({totalRoom:currentNumber+1});
    }
    setPersonCount = () => {
        let adults = 0,children = 0, resultString = '';
        $('.input-adults').each((index,element)=>{
            adults += element.value ? parseInt(element.value) : 0;
        })
        $('.input-children').each((index,element)=>{
            children += element.value ? parseInt(element.value) : 0;
        })
        //${adults>1?'s':''}
        if(adults){
            resultString += `${adults} Adult `;
        }
        if(children){
            resultString += `${children} Children`;
        }
        $('#persons').val(resultString);
    }
    handleCountPerson = (type,targetId) => {
        let target = $(`#${targetId}`);
        //console.log(target);
        let situation = target.val() ? parseInt(target.val()) : 0;
        //console.log(situation);
        if(type === COUNT_PERSON.PLUS){
            //console.log('Masuk ke plus')
            if(situation!==100){
                target.val(situation+1);
            }
        }else if(type === COUNT_PERSON.MIN){
            if(situation <= 1){
                target.val('');
            }else if(situation){
                target.val(situation-1);
            }
        }
        this.forceUpdate(()=>{console.log('already doing forceUpdate')});
    }
    toggleDropDown = () => {
        $('.dropdown-menu').addClass('show')
    }
    handleDateRangePickerApply = (event, picker) => {
        let { startDate, endDate } = picker;
        let startDateString = startDate.format('DD MMM YYYY');
        let endDateString = endDate.format('DD MMM YYYY');
        let totalNight = endDate.diff(startDate,'day');
        this.setState({startDateString,endDateString,totalNight});
    }

    handleLogout() {
        //localStorage.removeItem(config.KEY_ACCESS_CODE);
        this.props.logout();

        localStorage.removeItem(config.KEY_IS_MEMBER);
        localStorage.removeItem(config.KEY_CARD_NO);

        let $this = this;
        setTimeout(function() {
            $this.setState({ loginError: '', isSubmitLogin: false, isLoggedIn: false },()=>window.location.reload());
        }, 500);

        this.setState({ isSubmitLogout: true  });
    }
    componentWillReceiveProps(nextProps) {
        if ( this.state.isSubmitLogin && nextProps.loginReturn ) {
            if ( nextProps.loginReturn.error ) {

                if ( nextProps.loginReturn.message.toLowerCase() == 'link is inaccesible' && config.IS_ALLOW_LINK_ACCESSIBLE_TO_LOGIN ) {
                    this.setState({ isLoggedIn: true, isSubmitLogin: false });
                    $(".close").click();

                    localStorage.setItem(config.KEY_ACCESS_CODE, 'fake token');
                    window.location.reload();
                }
                else {
                    this.setState({ isLoggedIn: false, loginError: nextProps.loginReturn.message, isSubmitLogin: false });
                }
            }
            else if ( !nextProps.loginReturn.error ) {
                if ( nextProps.loginReturn.loggedIn ) {
                    this.setState({ isLoggedIn: true, isSubmitLogin: false });
                    $(".close").click();

                    localStorage.setItem(config.KEY_ACCESS_CODE, nextProps.loginReturn.accessToken);
                    localStorage.setItem(config.KEY_IS_MEMBER, nextProps.loginReturn.isMember);
                    window.location.reload();

                    //console.log(nextProps);
                }
                else {
                    this.setState({ isLoggedIn: false, isSubmitLogin: false, loginError: this.state.cardNumber.chartAt[0] === '8' ? 'Invalid Membership Card No.' : 'Invalid VISA Membership Card No.' });
                }

            }
        }
    }
    handleKeyUpLogin = (e) => {
        //console.log(e.keyCode);
        if(e.keyCode===13){
            this.handleLogin();
        }
    }
    render(){
		this.setPersonCount();

        let content = _getLocalStorage(config.KEY_CONTENT);
        let linkMessage = null;
        if ( content ) {
            content = JSON.parse(content);

            if ( content.link ) {
                linkMessage = content.link;
            }

            //console.log(linkMessage);
        }

        //console.log(DATE_NOW_STRING);
        return(
            <div>
				{ (this.state.isSubmitLogin || this.state.isSubmitLogout) ? _loading() : null}
                <header>
                    <div id="topbar">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div id="logo" className="pl-0">
                                        {/* <button onClick={this.openMenu} className="navbar-toggler toggler-example" type="button">
                                            <span className="text-primary">
                                                <FontAwesomeIcon icon="bars"/>
                                            </span>
                                        </button> */}
                                        <a href="https://www.dresort.com.sg"><img src={MyAssets.img.logo} alt=""/></a>
                                    </div>
                                </div>
                                {/*<div className="col-lg-9 offset-1 col-sm-12">
                                    <ul className="topbar-menu">
                                        <li>
                                            <div className="input-group input-group-sm" id="browse-resort">
                                                <select defaultValue="" id="other-resorts">
                                                    <option value="">Browse other resorts</option>
                                                    <option value="1">D'Resort @ Downtown East</option>
                                                    <option value="2">Costa Sands Resort (Sentosa)</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li><NavLink to="#">Destination</NavLink></li>
                                        <li><NavLink to="#">Stay</NavLink></li>
                                        <li><NavLink to="#">Experience</NavLink></li>
                                        <li><NavLink to="#">Meetings & Events</NavLink></li>
                                        <li><NavLink to="#">Linkpoints</NavLink></li>
                                        <li><NavLink to="#">Offers & Packages</NavLink></li>
                                        <div onClick={this.closeMenu} className="close-btn">
                                            <FontAwesomeIcon style={{fontSize:24}} icon="times"/>
                                        </div>
                                    </ul>
                                </div>*/}
                            </div>
                            { this.state.isLoggedIn ?
                            <div id="login">
                                <p>Welcome!&nbsp;&nbsp;&nbsp;|</p>

                                { config.IS_DEVELOPMENT ? <p className="hide-mobile"><NavLink to="/managebooking">Manage Booking</NavLink>&nbsp;&nbsp;&nbsp;| </p> : null }

                                <button className="btn btn-sm btn-square btn-success" onClick={ this.handleLogout.bind(this) }>Logout</button>
                            </div>
                            :
                            <div id="login">

                                { config.IS_DEVELOPMENT ? <p className="hide-mobile"><NavLink to="/managebooking">Manage Booking</NavLink>&nbsp;&nbsp;&nbsp;| </p> : null }

                                <button className="btn btn-sm btn-square btn-success" data-toggle="modal" data-target="#login-modal">NTUC Member Log in</button>


                            </div> }
                        </div>
                    </div>
                </header>
                {/*<section className="banner-slider wrap">
                    <div className="banner-slider-inner">
						<div className="jumbotron jumbotron-fluid">
							<div className="jumbotron-background" style={{backgroundImage:`url(${BackgroundImage})`}}>
							</div>
						</div>
						<div className="static">
							<img src={MyAssets.img.bannerTwo} alt="" style={{height:'auto',width:'100%',marginLeft:'0px'}}/>
						</div>
                    </div>
					{this.state.hideMiddleBar ? null :
                    <div id="header-filter">
						<div className="container">
							<div className="row">

								<div className="col-4 col-md-2">
									<span className="input-group input-group-light-prepend">
									<div className="input-group-prepend hide-xs-prepend">
										<button className="btn " type="button">
										<i className="icon light-calendar"></i>
										</button>
									</div>
									<DateRangePicker minDate={DATE_NOW_STRING} onApply={(event,picker)=>this.handleDateRangePickerApply(event,picker)} containerStyles={{position:'absolute',width:'100%'}}>
									<input value={this.state.startDateString} onChange={()=>{}} type="text" style={{height:'calc( 1.8em + 1.5em + 2px',fontWeight:'600',fontSize:'0.9em',border:'none',borderRadius:0,paddingLeft:isMobileView()?'6px':'50px'}} className="form-control date-picker" id="date-start" placeholder="Check In Date"
										autoComplete="off"/>
									</DateRangePicker>
									</span>
								</div>

								<div id="count_days"> <span>{this.state.totalNight} Night{this.state.totalNight>1?'s':''}</span> </div>

								<div className="col-4 col-md-2">
									<span className="input-group input-group-light-prepend">
									<DateRangePicker minDate={DATE_NOW_STRING} onApply={(event,picker)=>this.handleDateRangePickerApply(event,picker)} containerStyles={{position:'absolute',width:'100%'}}>
									<input value={this.state.endDateString} onChange={()=>{}} type="text" style={{height:'calc( 1.8em + 1.5em + 2px',fontWeight:'600',fontSize:'0.9em',border:'none',borderRadius:0,paddingLeft:'40px'}} className="form-control date-picker" id="date-end" placeholder="Check Out Date"
										autoComplete="off"/>
									</DateRangePicker>
									</span>
								</div>

								<div id='person-input-container' className="col-4 col-md-3">
									<span className="input-group input-group-light-prepend ">
										<div className="input-group-prepend hide-xs-prepend">
											<button className="btn " type="button">
												<i className="icon light-people"></i>
											</button>
										</div>
										<input onFocus={()=>this.showHideCountPerson(true)} type="text" className="form-control count-person-pop-up" id="persons" placeholder="2 Adult, 1 Children" autoComplete="off"/>
									</span>
									<div className="count-person-pop-up" style={this.countPersonStyle()} id="count_person">
										<span className="person_title count-person-pop-up">Max. 3 guests per room</span>
										<div className="container-grey count-person-pop-up">
											<div className="container count-person-pop-up">
												<div className="row count-person-pop-up">
													<div className="col-2 col-lg-2 count-person-pop-up"></div>
													<div className="col-5 col-lg-5 count-person-pop-up">Adult(s)</div>
													<div className="col-5 col-lg-5 count-person-pop-up">Children (under 12)</div>
												</div>
											</div>
										</div>
										<div className="count-person-pop-up">
											<div className="container count-person-pop-up" id="row-room">
												<div className="row input-spin count-person-pop-up">
													<div className="col-2 col-lg-2 count-person-pop-up">
														<span className="room count-person-pop-up">Room 1</span>
													</div>
													<div className="col-5 col-lg-5 count-person-pop-up">
														<div className="input-group input-group-sm count-person-pop-up">
															<div className="input-group-prepend count-person-pop-up">
																<button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-1`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
															</div>
															<input onChange={()=>{}} id="input-adults-1" defaultValue="" placeholder='0' className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
															<div className="input-group-append count-person-pop-up">
																<button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-1`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
															</div>
														</div>
													</div>
													<div className="col-5 col-lg-5 count-person-pop-up">
														<div className="input-group input-group-sm count-person-pop-up">
															<div className="input-group-prepend count-person-pop-up">
																<button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-1`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
															</div>
															<input onChange={()=>{}} id='input-children-1' defaultValue="" placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
															<div className="input-group-append count-person-pop-up">
																<button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-1`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='count-person-pop-up'>
											<div className="container count-person-pop-up" id="add-more-room">
												<button onClick={this.addNewRoom} className="btn-square btn btn-sm btn-success count-person-pop-up">ADD ROOM</button>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-3">
									<span className="input-group input-group-light-prepend" id="special_code_wrapper">
									<div className="input-group-prepend">
										<button className="btn " type="button">
										<i className="icon light-tag"></i>
										</button>
									</div>
									<input onFocus={()=>this.showHideSpesialCode(true)} type="text" className="form-control spesial-code-pop-up" placeholder="Special Code" autoComplete="off"/>
									</span>
									<div className="spesial-code-pop-up" style={{position:'absolute',width:300,background:'white'}} id="popup-specialcode">
										<div className="container spesial-code-pop-up">
											<p className='spesial-code-pop-up'>Corporate/Special Rate (Optional)</p>
											<div className='spesial-code-pop-up'>
												<select className="custom-select form-sm spesial-code-pop-up">
													<option val="">Select Code Type</option>
													<option val="1">Coupon</option>
												</select>
											</div>
											<div className='spesial-code-pop-up'>
												<input type="text" className="form-control custom-select form-sm spesial-code-pop-up" placeholder="Enter Code"/>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<button className="btn btn-xl btn-success btn-square">SEARCH</button>
								</div>
							</div>
						</div>
                    </div>
					}
                </section>*/}
				{/*this.state.hideMiddleBar ? null :
                <div id="book-step">
                    <div className="container">
                        <div className="row">
                            <div className="step active" data-id="1">
                                <span style={{paddingTop:2}} className="number-count">1</span>Select Room & Dates
                            </div>

                            <div className="step next" data-id="2">
                                <span style={{paddingTop:2}} className="number-count">2</span>Select Optional Add-Ons
                            </div>

                            <div className="step next" data-id="3">
                                <span style={{paddingTop:1}} className="number-count">3</span>Guest Information & Payment
                            </div>
                            <div className="step next" data-id="4">
                                <span style={{paddingTop:1}} className="number-count">4</span>Confirmation
                            </div>
                            <div className="vertical-line">
                            </div>
                            <div id="sort">
                                <div className="btn-group position-relative" id="sort-list">
                                    <button type="button" onClick={this.toggleDropDown} className="btn btn-none dropdown-toggle">Views By: <span id="sort-by">Rooms</span></button>
                                    <div className="dropdown-menu" id="sort-dropdown">
                                        <NavLink exact className="dropdown-item" to="/">Rooms</NavLink>
                                        <NavLink exact className="dropdown-item" to="/byrates">Rates</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/
				}
                {/*isMobileView() ?
                (
                    <div className="container" id="textStep">
                        <div className="row">
                            <p>Select Room & Dates</p>
                        </div>
                    </div>
                ) :
                null
                */}
                {/* <section id="total-reservation" className="show-mobile">
                    <div className="container">
                        <div className="row reservation">
                            <div className="col-6 left">
                                <p className="grand-total"><span className="count">3x</span> | Grand Total: <span className="price">S$703.20</span></p>
                                <p className="link" data-toggle="modal" data-target="#reservation-modal">View/Modify</p>
                            </div>
                            <div className="col-6 right">
                                <p>Make this reservation</p>
                            </div>
                        </div>
                    </div>
                </section> */}

				<div className="modal fade" id="login-modal" tabIndex="-1" role="dialog" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<div className="modal-header">
									<h4 className="modal-title">Welcome to D'Resort</h4>
									<button type="button" className="close" data-dismiss="modal">&#10005;</button>
								</div>
								{/*<div className="form-group">
									<input type="text" className="form-control" id="email" placeholder="Email address" />
								</div>*/}

								<div className="form-group">
                    <label htmlFor="email">Log in to enjoy additional privileges and more</label>
                    <input type="text" onKeyUp={this.handleKeyUpLogin} value={ this.state.cardNumber } className="form-control" id="number" placeholder="Enter your Membership Card No. here" maxLength="16"
                        onChange={ this.handleCardNumber.bind(this) } />
								</div>

                <img className="membercardimage pb-3 img-fluid d-none d-md-block d-xl-block" src={`https://cdn-dresort.aidencreative.com` + MemberCardImage} />
                <img className="membercardimage pb-3 img-fluid d-block d-sm-none" src={`https://cdn-dresort.aidencreative.com` + MemberCardMobileImage} />

                {/*<div class="col-12 alert alert-info">
                    <div>
                        <p class="mb-2"><strong>SCHEDULED MAINTENANCE FOR MEMBERSHIP LOG IN PAGE</strong></p>
                        <p>We would like to inform you of an upcoming emergency production application deployment scheduled <strong>from 12 Feb 2020, 11:00pm to 14 Feb 2020, 03:00am | SGT</strong>. During this period, there will be an intermittent/no connection to the NTUC's member profile.</p>
                        <p>Thanks for your understanding and support.</p>
                    </div>
                </div>*/}

                { linkMessage ?
                  <div className="col-12 alert alert-info maintenance">
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkMessage) }}>
                      </div>
                  </div>
                  : null
                }

                {/*<div class="col-12 alert alert-info">
                    <div>
                        <p class="mb-2"><strong>SCHEDULED MAINTENANCE FOR MEMBERSHIP LOG IN PAGE</strong></p>
                        <p>We would like to inform you of an upcoming emergency production application deployment scheduled <strong>from 12 Jan 2020, 03:00am to 06:00am (3 hours) | SGT</strong>. During this period, there will be an intermittent/no connection to the NTUC's member profile related APIs but the transaction APIs and POS will be working as usual.</p>*/
                        /*<p>We would like to inform you of an upcoming emergency production application deployment scheduled <strong>from 12 Jan 2020, 03:00am to 13 Jan 2020, 06:00am (3 hours) | SGT</strong>. During this period, there will be an intermittent/no connection to the NTUC's member profile related APIs but the transaction APIs and POS will be working as usual.</p>*/
                      /*<p>Kindly note that we will be experiencing downtime for scheduled maintenance and upgrades <strong>from 09 Dec 2019, 11:00 pm to 10 Dec 2019, 03:00am (4 hours) | SGT</strong></p>*/
                        /*<p>Thanks for your understanding and support.</p>
                    </div>
                </div>*/}

								{/*<a href="" className="forgot-link"> Reset or Create Password</a>*/}
                { this.state.loginError !== '' ? <span style={{color: 'red'}}>{this.state.loginError}</span> : null }

                <button className="btn-md btn btn-success btn-login" id="btn-login" disabled={ this.state.cardNumber.length != 16 ? true : false } onClick={ this.handleLogin.bind(this) }>
									LOG IN
								</button>

								{/*<span className="login-or"></span>

								<button className="btn-md btn btn-primary btn-login" id="login-fb">
									<img src={MyAssets.icon.fbIcon} />
									Login with Facebook
								</button>

								<p>Don't have an account? <a href="" className="signup-link">Sign up</a></p>*/}

							</div>
						</div>
					</div>
				</div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { loginReturn: null }

    //console.log(state);console.log("");

    if ( state.login.result ) {
        params.loginReturn = state.login.result;

        //console.log(params.login);
    }

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { login, logout } = allAPI;
  return bindActionCreators({ login, logout }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Main));
