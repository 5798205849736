import React from 'react';

import * as types from '../const/types';
import MyAssets from '../assets';

//import { _getLocalStorage } from './helper_functions';

//import { Link } from 'react-router-dom';


export const _loading = () => {
	
	return (
		<div className="loading-overlay">
			<div className="loading-container">
				<img src={`https://cdn-dresort.aidencreative.com` + MyAssets.img.loading} />
			</div>
		</div>
	);
}